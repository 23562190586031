import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencySearch'
})
export class CurrencySearchPipe implements PipeTransform {

    transform(orderList: Array<any>, search: string): any {

        if (search) {
            let input = search.toUpperCase();
            return orderList.filter((d) => d.indexOf(input) > -1);
        } else {
            return orderList;
        }
    }

}