<div ly-dialog-content [lyPx]="0">

    <div *ngIf="ready">
      <button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
      <button (click)="cropper.zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button>
      <button (click)="cropper.center()" ly-button appearance="icon"><ly-icon>filter_center_focus</ly-icon></button>
      <button (click)="cropper.rotate(-90)" ly-button appearance="icon"><ly-icon>rotate_90_degrees_ccw</ly-icon></button>
      <button (click)="cropper.fit()" ly-button>Fit</button>
      <button (click)="cropper.fitToScreen()" ly-button>Fit to screen</button>
      <button (click)="cropper.setScale(1)" ly-button>1:1</button>
    </div>
  
    <ly-img-cropper
      [config]="myConfig"
      [(scale)]="scale"
      (ready)="ready = true"
      (cleaned)="ready = false"
      (minScale)="minScale = $event"
      (cropped)="onCropped($event)"
      (loaded)="onLoaded($event)"
      (error)="onError($event)"
    >
      <span>Drag and drop image</span>
    </ly-img-cropper>
  
    <div [className]="classes.sliderContainer">
      <div [class]="classes.slider">
        <ly-slider
          [thumbVisible]="false"
          [min]="minScale"
          [max]="1"
          [(ngModel)]="scale"
          (input)="onSliderInput($event)"
          [step]="0.000001"
          cdkFocusInitial></ly-slider>
      </div>
    </div>
  
  </div>
  
  <div container *ngIf="ready" [lyJustifyContent]="'flex-end'" ly-dialog-actions>
    <button  class="btn btn-default" (click)="dialogRef.close()">CANCEL</button>
    <button class="btn shippn-from-to-area-go-btn" (click)="dialogRef.close(cropper.crop())">DONE</button>
  </div>