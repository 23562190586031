import { Component, OnInit, ViewEncapsulation, HostListener, HostBinding, ElementRef, AfterViewInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';

@Component({
  selector: 'app-countdown-bar',
  templateUrl: './countdown-bar.component.html',
  styleUrls: ['./countdown-bar.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CountdownBarComponent implements OnInit, AfterViewInit {
  announcementData;
  announcemenetHTML;
  isCountdownOn: Boolean = false;
  leftDays;
  leftHours;
  leftMinutes;
  leftSeconds;
  countdownEndDate;
  constructor(private signUpService: SignupService, private el: ElementRef, private watcherService: WatcherService) {
  }
  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resizeWorks();
  }

  @HostBinding('style.height.px')
  elHeight: number;
  private resizeWorks(): void {

    if (this.announcementData) {
      let windowSize = window.innerWidth;
      if (windowSize < 768) {
        this.elHeight = this.el.nativeElement.offsetHeight;
        const shadowRoot: DocumentFragment = this.el.nativeElement.shadowRoot;
        if (shadowRoot.querySelector('.banner-with-countdown')) {
          let announcementBannerHeight = shadowRoot.querySelector('.banner-with-countdown').clientHeight;
          this.watcherService.activeAnnouncementBanner(true);
          this.watcherService.announcementBannerHeightUpdate(announcementBannerHeight);
        }
      } else {
        this.watcherService.activeAnnouncementBanner(false);
      }
    }
  }

  ngOnInit(): void {
    this.signUpService.getAnnouncementBanner().subscribe(banner => {
      this.announcementData = banner['data'];
      this.announcemenetHTML = banner['data']['html'];


      if (banner['data'].countdown == "1") {
        this.countdownEndDate = banner['data']['end_date'] * 1000;
        this.countdown(this.countdownEndDate)
      }
    })
  }
  ngAfterViewInit(): void {
    this.onResize();
  }

  openAnnouncementUrl() {

    if (this.announcementData.url.length > 0) {
      let urlTarget = this.announcementData.url_new_tab === 1 ? '_blank' : '_self';
      window.open(this.announcementData.url, urlTarget, "noopener");
    }


  }


  countdown(endDate) {


    setInterval(() => {

      let now = new Date().getTime();

      let distance = endDate - now;

      this.leftDays = Math.floor;

      this.leftDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.leftHours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.leftMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.leftSeconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance <= 0) {
        this.announcementData.status = 0;
        this.announcementData.countdown = null;
      }


    }, 1000)


  }

}
