import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, AfterContentChecked, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NotificationsInfiniteScrollService } from 'src/app/services/notifications-infinite-scroll.service';
import { SignupService } from 'src/app/services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';
import { AppState } from 'src/app/store/app.reducer';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'shippn-notifications',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NotificationCenterComponent implements OnInit, OnChanges, AfterContentChecked {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;


  // SHPN-5502
  isShopper = false;
  checkboxControl = new UntypedFormGroup({
    sendCopyCheckbox: new UntypedFormControl(false)
  })

  memberDetails;
  userID;
  token;
  messageData;
  messageType = null
  messageAreaType = 0
  messageAreaTypeText = ""
  messageTypeText = ""
  textArea = "";
  textAreaPlaceholder = "Please select the tab for the recipient you want to send a message from above";

  @Input() conversationID;
  @Input() orderType;
  @Input() orderNumber;

  @Input() notificationMessages;
  userData;
  notificationsForCenter;
  lang='en';
  constructor(
    public signUpService: SignupService,
    private toastr: ToastrService,
    private cdsref: ChangeDetectorRef,
    private router: Router,
    private notifyScrollService: NotificationsInfiniteScrollService, 
    private watcherService: WatcherService,
    private store: Store<AppState>
  ) {
    this.store.select('auth').subscribe(state => {
      this.token = state.token;
      this.userID = state.user?.id;
    })
    
    this.watcherService.getLang().subscribe( lang => {
      this.lang = lang;
    })
    this.signUpService.getUserByUserID(this.userID, this.token).subscribe(
      user => {
        this.userData = user['data'];
      }
    )
    this.messageData = [];

    // For Scroll Service
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // this.getNotificationsData(true)

  }

  ngOnInit(): void {
  }
  ngAfterContentChecked(): void {
    this.cdsref.detectChanges();
    if (this.notificationMessages) {
      if (this.userData && this.userData.type == 1) {
        this.notificationsForCenter = this.notificationMessages
        this.messageData = this.notificationsForCenter;

      } else if (this.userData && this.userData.type == 2) {
        this.notificationsForCenter = this.notificationMessages
        this.messageData = this.notificationsForCenter;
      } else {
        return;
      }
    }
    // window.scroll(0, 0);

    
  }
  ngOnChanges() {
    if (this.notificationMessages) {
      if (this.userData && this.userData.type == 1) {
        this.notificationsForCenter = this.notificationMessages;
        this.messageData = this.notificationsForCenter;

      } else if (this.userData && this.userData.type == 2) {
        this.notificationsForCenter = this.notificationMessages;
        this.messageData = this.notificationsForCenter;
      } else {
        return;
      }
    }
    // window.scroll(0, 0);
    this.messageData = this.notificationsForCenter;
    // this.getNotificationsData(true)
  }
  goToOrderDetail(message) {
    if (this.userData.type == 1) {
      if (message.order_type == 'SHIPPN_ORDER') {
        this.router.navigateByUrl(this.lang +`/member/package-detail/${message.id}?openmessages=true`);
      }
      if (message.order_type == 'BFM_ORDER') {
        this.router.navigateByUrl(this.lang +`/member/bfm-order-detail/${message.bfm_obj[0].id}?openmessages=true`);
      }
    }
    if (this.userData.type == 2) {
      if (message.order_type == 'SHIPPN_ORDER') {
        this.router.navigateByUrl(this.lang +`/member/host-package-detail/${message.id}?openmessages=true`);
      }
      if (message.order_type == 'BFM_ORDER') {
        this.router.navigateByUrl(this.lang +`/member/host-bfm-order-detail/${message.bfm_obj[0].id}?openmessages=true`);
      }
    }
  }

  getNotificationsData(fetchData: boolean) {
    if (fetchData) {
      let startVal;
      if (this.messageData && this.messageData.length > 0) {
        startVal = this.messageData.length - 1;
      } else {
        startVal = -1;
      }
      this.notifyScrollService.fetchNotifications(startVal, this.notificationsForCenter).subscribe(
        (response) => {
          this.messageData = this.messageData.concat(response);
          this.cdsref.markForCheck()
          this.cdsref.detectChanges();
        },
        (err) => {

        }
      );
    }
  }
}