<div>
    <div class="shippn-homepage-container container">
        <div class="row">
            <div style="text-align: left;" class="col-md-12">


                <p>
                    The privacy policy is valid and effective for the “Website” serving under www.shippn.com domain
                    name. By using the Website, you consent to the collection and use of your information in the manner
                    we describe in this Privacy Policy.
                </p>

                <ul>
                    <li>
                        <p>
                            Shippn (referred to as “Shippn”, “we”, or “us”) operates a platform and community
                            marketplace that helps people shop from anywhere in the world. Shippn hosts help shoppers
                            from all around the world to have a local address in every country and shop conveniently
                            like there are no borders whether through our website or our mobile applications
                            (“Platform”).
                        </p>
                    </li>
                    <li>
                        <p>
                            SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ., (“Shippn”) is committed to protecting the privacy of your personal
                            information.
                            Personal information includes information about you, such as your name, address, email
                            address, telephone number, and financial information that is not otherwise publicly
                            available. The information you have provided will not be shared with third parties, unless a
                            legal sanction is received. We have physical, electronic, and procedural safeguards that
                            comply with regulations to protect personal information about you.
                        </p>
                    </li>
                    <li>
                        <p>
                            The most fundamental privacy principle we follow is that by default, anything you buy or
                            ship via Shippn is private to you and your host except official border control officials.
                        </p>
                    </li>
                    <li>
                        <p>
                            Shippn.com collects personal information when you register with Shippn. This information may
                            include your name, address, e-mail address, zip code, occupation, birth date and personal
                            interests. Shippn does not rent, sell, or share personal information about you with other
                            people or nonaffiliated companies except to provide products or services you’ve requested
                            with your permission.
                        </p>
                    </li>
                </ul>

                <p>
                    As SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ., we want to make sure that you are informed about your rights in terms of protecting
                    your data.
                </p>

                <ul   >
                    <li>
                        <p>
                            Right to Access: You have the right to request what personal data we have collected about
                            you. This data will be compiled by us and transmitted to you. The cost of this service will
                            be charged from you, depending on the mode of transmission and your demands.
                        </p>
                    </li>
                    <li>
                        <p>
                            Right to Rectification: If you think that there is any inaccuracy or deficiency in the
                            personal data collected about you, you can contact us to correct this data and eliminate the
                            deficiencies.
                        </p>
                    </li>
                    <li>
                        <p>
                            Right to Erasure: You can request the deletion and destruction of the data that we hold. In
                            order for your right to be exercised, the use and processing of such data should not be
                            essential for SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ..
                        </p>
                        <p>
                            Our app enables users to upload profile pictures for their accounts. To provide this feature, we collect and process profile pictures from users. We recognize the importance of protecting user privacy and we are committed to ensuring that user data is handled in accordance with the General Data Protection Regulation (GDPR).
                        </p>
                        <p>
                            When users upload profile pictures, we collect the image file and store it securely on our servers. We may also process the image to optimize its size or format for display on our app. We do not use or disclose the profile pictures for any purposes other than enabling the user to have a profile picture associated with their account.
                        </p>
                        <p>
                            We do not share or sell profile pictures to third parties, except as required by law or as necessary to provide the service to users (for example, to display a user's profile picture to other users on the app). We may also use aggregate or anonymized data (that does not identify individual users) for analytics or marketing purposes.
                        </p>

                        <p>
                            We take appropriate technical and organizational measures to protect user data, including profile pictures, from unauthorized access, disclosure, alteration, or destruction. We implement encryption and access controls to protect user data. However, no security measures can provide absolute protection, so users should be aware that there is always a risk of unauthorized access or disclosure.
                        </p>
                        <p>
                            Users have the right to access, modify, or delete their profile pictures at any time. Users can do this by accessing their account settings in the app. Users can also request that we delete their profile picture and associated data by contacting us at <a style="color:#222; font-weight: bold; text-decoration: underline;" href="https://www.shippn.com/en/contact-us">here</a> 
                        </p>
                        <p>
                            We process profile pictures based on the user's consent. By uploading a profile picture, users give their explicit consent to the collection, processing, and storage of their profile picture data as described in this privacy policy. Users have the right to withdraw their consent at any time by deleting their profile picture or contacting us.
                        </p>
                        <p>
                            We may transfer profile picture data to third countries or international organizations if necessary for the provision of the service, subject to appropriate safeguards to ensure that the data is protected in accordance with the GDPR.
                        </p>
                        <p>
                            We may update this privacy policy from time to time to reflect changes in our data practices or legal requirements. Users should check this policy periodically for updates.
                        </p>
                    </li>
                </ul>

                <p>
                    You can also permanently delete your account by using the "Delete Account" option on your profile
                    page.
                </p>

                <ul   >
                    <li>
                        <p>
                            Go to your profile page.
                        </p>
                    </li>
                    <li>
                        <p>
                            Then select the Delete Account option.
                        </p>
                    </li>
                    <li>
                        <p>
                            Select a reason and click delete button.
                        </p>
                    </li>
                </ul>

                <p>
                    To exercise these rights, you can contact us with the information below.
                </p>

                <p>
                    Phone: 1-740-848-5020 ​<br>
                    Email: help@shippn.com ​<br>
                    Mail: Atatürk Mh. Ertuğrul Gazi Sk. D2 Blok No: 2R İç Kapı No: 15 Ataşehir / İstanbul / 34758 / Türkiye​<br>
                </p>

                <p>
                    Your Facebook data is used only for login. According to the Facebook Platform rules, we have to
                    provide the User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to delete
                    your activities for the Shippn, follow these instructions:
                </p>

                <ul   >
                    <li>
                        <p>
                            Go to Your Facebook Account’s Setting & Privacy. Click ” Setting “.
                        </p>
                    </li>
                    <li>
                        <p>
                            Then, go to ” Apps and Websites” and you will see all of your Apps activities.
                        </p>
                    </li>
                    <li>
                        <p>
                            Select the option box for Shippn
                        </p>
                    </li>
                    <li>
                        <p>
                            Click ” Remove” button.
                        </p>
                    </li>
                </ul>

            </div>


        </div>

        <div class="row">
            <div class="col-md-4">
                <img style="width: 100%;" src="../../assets/img/privacy-policy-icon.png"
                    alt="shippn privacy policy icon">
            </div>
            <div class="col-md-8">
                <ul   >
                    <li>
                        <p>
                            When you purchase shipping service from Shippn, your Card Details and any other information
                            you have entered, are encrypted by a safety network, which is symbolized by the small key
                            icon on your browser’s address bar, and transferred to our systems encrypted. Thus, it
                            becomes impossible to reach, read or use these data by third parties. Your credit card
                            number, expiry date, CVV2 code are all securely encrypted data and can not be viewed by the
                            company employees.
                        </p>
                    </li>
                </ul>
            </div>
        </div>



        <div class="row">
            <div class="col-md-12">
                <ul   >
                    <li>
                        <p>
                            We limit access to personal information about you to host and employees who we believe
                            reasonably need to come into contact with that information to provide products or services
                            to you.
                        </p>
                    </li>
                    <li>
                        <p>
                            Automatically collected information
                        </p>
                    </li>
                    <li>
                        <p>
                            Website may automatically collect some information such as the name of your internet service
                            provider, Internet Protocol (IP) address, type of browser and operating system that you use,
                            the date and time that you access to the Website, the pages you visited within the Website
                            and what website you linked from to our Website and other similar analytical data. These
                            information are being used to improve the Website, to analyze the user tendencies and to
                            manage the Website. These statistics does not contain the personal information.
                        </p>
                    </li>
                    <li>
                        <p>
                            Visitor’s Identity
                        </p>
                    </li>
                    <li>
                        <p>
                            Time to time, soma ‘data’ may placed in your computer to help us better know you. This data
                            is mostly known as “cookie”. Our use of cookies helps us to follow when and how our visitors
                            use the Website, which in return used to improve our Website. Cookie use is a sector
                            standart and almost all websites use cookies to gather data. Cookies are being saved to your
                            computer, not at the Website. You may disable the Internet technology tools, including
                            cookies, by adjusting your browser preferences on your computer; however, this may limit
                            your ability to take advantage of all the features of this Website.
                        </p>
                    </li>
                    <li>
                        <p>
                            Changes to the Privacy Policy
                        </p>
                    </li>
                    <li>
                        <p>
                            Shippn may update this policy. We will notify you about significant changes in the way we
                            treat personal information, by sending a notice to the primary email address specified in
                            your Shippn account or by placing a prominent notice on our Website.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div style="margin-top: 50px;" class="shippn-homepage-container container">
    <div class="row">
        <div class="col-md-3">
            <img style="width: 100%;" src="../../assets/img/privacy-policy-icon-2.png"
                alt="shippn privacy policy icon 2, cookies">
        </div>
        <div class="col-md-9">
            <h2 style="font-size: 38px;font-weight: 900;">COOKIE POLICY <br>
                DISCLOSURE TEXT</h2>

            <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                Dear Site Visitors, Customers, Members, <br>
                As SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ., cookies, pixels, gif. We make use of some technologies (“cookies”) such as format
                files.<br>
                In this text:<br>
            </p>

            <ul style="list-style: unset;color: black;padding-left: 15px;">
                <li>
                    <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                        Visiting the “https://www.shippn.com/“ website (“Site”) or requesting a cargo delivery from the
                        Site,
                        Cookies used in the form, there is an informative text about how you can control these cookies.
                    </p>
                </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div style="margin-top: 20px;" class="col-md-12">
            <h6 style="color: #fc7137;">1. What is a Cookie?</h6>
            <hr style="border-color: #fc7137;margin-top: 10px;margin-bottom: 10px;">

            <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                A cookie is a small text file required for a website to recognize you later. This text file is unique to
                you and can only be read by the network server that defines this code. It is not a virus. It saves you
                time and if you are registered, it automatically remembers your answers on your next entries.
            </p>

            <h6 style="color: #fc7137;">2. For What Purposes Are Cookies Used?</h6>
            <hr style="border-color: #fc7137;margin-top: 10px;margin-bottom: 10px;">

            <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                As SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ. LCC, we use cookies for various purposes on our site and process your personal data
                through
                these cookies. These purposes are mainly:
            </p>

            <ul style="list-style: unset;color: black;padding-left: 15px;">
                <li>
                    <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                        To perform the basic functions required for the operation of the site.
                    </p>
                </li>
                <li>
                    <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                        Analyzing the Site and increasing the performance of the Site.
                    </p>
                </li>
                <li>
                    <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                        To increase the functionality of the site and to provide ease of use.
                    </p>
                </li>
                <li>
                    <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                        To provide personalized products and services according to people’s tastes, usage habits and
                        needs.
                    </p>
                </li>
                <li>
                    <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                        To ensure the legal security of the Site, you and our Company.
                    </p>
                </li>
            </ul>

            <h6 style="color: #fc7137;">3. What are the Types of Cookies Used on the Site?</h6>
            <hr style="border-color: #fc7137;margin-top: 10px;margin-bottom: 10px;">

            <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                Session cookies on the site (temporary cookies kept on your device until you leave the website), third
                party cookies (cookies set by third parties that offer content on the website), mandatory cookies
                (cookies necessary for the basic functions of the website to function properly), functional and
                analytical cookies (The cookies are used to remember the preferences, to use the website effectively, to
                organize the site to respond to user requests and to determine how visitors use the site).
            </p>

            <div style="box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);" class="accordion" id="accordionExample">
                <div class="card">
                    <div style="border: none;background: #eaeaea;cursor: pointer;" data-toggle="collapse"
                        data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="card-header"
                        id="headingOne">
                        <div style="float: left;">
                            <h2 class="mb-0">
                                <button
                                    style="padding: 0;color: #222222;text-decoration: none;font-size: 15px;font-weight: bold;"
                                    class="btn btn-link btn-block text-left" type="button">
                                    STRICTLY NECESSARY COOKIES
                                </button>
                            </h2>
                        </div>

                        <div style="float: right;">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>

                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr style="border-bottom: 1px solid #2222222e;">
                                            <th style="font-size: 14px;" scope="col">Cookie Name</th>
                                            <th style="font-size: 14px;" scope="col">Service Provider</th>
                                            <th style="font-size: 14px;" scope="col">Purpose of Use</th>
                                            <th style="font-size: 14px;" scope="col">Type of Cookie</th>
                                            <th style="font-size: 14px;" scope="col">Storage Period</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px;">shippn_user</td>
                                            <td style="font-size: 14px;">Website</td>
                                            <td style="font-size: 14px;">Used to recognize the session on your device
                                            </td>
                                            <td style="font-size: 14px;">1st Party Cookie</td>
                                            <td style="font-size: 14px;">Session</td>
                                        </tr>

                                        <tr>
                                            <td style="font-size: 14px;">[SessionID#27]</td>
                                            <td style="font-size: 14px;">PayPal</td>
                                            <td style="font-size: 14px;">Based on specific configuration for Shippn.com,
                                                a buyer's location and cookies, PayPal dynamically presents the
                                                appropriate funding sources to give our customers more ways to pay.</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">3 year</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="card">
                    <div style="border: none;background: #eaeaea;cursor: pointer;" data-toggle="collapse"
                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" class="card-header"
                        id="headingOne">
                        <div style="float: left;">
                            <h2 class="mb-0">
                                <button
                                    style="padding: 0;color: #222222;text-decoration: none;font-size: 15px;font-weight: bold;"
                                    class="btn btn-link btn-block text-left" type="button">
                                    PERFORMANCE COOKIES
                                </button>
                            </h2>
                        </div>

                        <div style="float: right;">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>

                    <div id="collapseTwo" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr style="border-bottom: 1px solid #2222222e;">
                                            <th style="font-size: 14px;" scope="col">Cookie Name</th>
                                            <th style="font-size: 14px;" scope="col">Service Provider</th>
                                            <th style="font-size: 14px;" scope="col">Purpose of Use</th>
                                            <th style="font-size: 14px;" scope="col">Type of Cookie</th>
                                            <th style="font-size: 14px;" scope="col">Storage Period</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px;">_ga</td>
                                            <td style="font-size: 14px;">Google</td>
                                            <td style="font-size: 14px;">Registers a uniqueID that is used to generate
                                                statistical data on how the visitor uses the website.</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">2 years</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="card">
                    <div style="border: none;background: #eaeaea;cursor: pointer;" data-toggle="collapse"
                        data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                        class="card-header" id="headingOne">
                        <div style="float: left;">
                            <h2 class="mb-0">
                                <button
                                    style="padding: 0;color: #222222;text-decoration: none;font-size: 15px;font-weight: bold;"
                                    class="btn btn-link btn-block text-left" type="button">
                                    FUNCTIONAL COOKIES
                                </button>
                            </h2>
                        </div>

                        <div style="float: right;">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>

                    <div id="collapseThree" class="collapse" aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr style="border-bottom: 1px solid #2222222e;">
                                            <th style="font-size: 14px;" scope="col">Cookie Name</th>
                                            <th style="font-size: 14px;" scope="col">Service Provider</th>
                                            <th style="font-size: 14px;" scope="col">Purpose of Use</th>
                                            <th style="font-size: 14px;" scope="col">Type of Cookie</th>
                                            <th style="font-size: 14px;" scope="col">Storage Period</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px;">G_ENABLED_IDPS</td>
                                            <td style="font-size: 14px;">Google</td>
                                            <td style="font-size: 14px;">Used for secure login to the website with a
                                                Google account</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">Session</td>
                                        </tr>

                                        <tr>
                                            <td style="font-size: 14px;">fbsr_</td>
                                            <td style="font-size: 14px;">Facebook</td>
                                            <td style="font-size: 14px;">Used by Facebook Contains the signed request
                                                for the Facebook App user. to track signed-in user</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">1 Month</td>
                                        </tr>

                                        <tr>
                                            <td style="font-size: 14px;">__paypal_storage__</td>
                                            <td style="font-size: 14px;">PayPal</td>
                                            <td style="font-size: 14px;">Used in context with the PayPal
                                                payment-function on the website. The cookie is necessary for making a
                                                safe transaction through PayPal.</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">Persistent</td>
                                        </tr>

                                        <tr>
                                            <td style="font-size: 14px;">enforce_policy</td>
                                            <td style="font-size: 14px;">PayPal</td>
                                            <td style="font-size: 14px;">This cookie is provided by Paypal. The cookie
                                                is used in context with transactions on the website - The cookie is
                                                necessary for secure transactions.</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">1 year</td>
                                        </tr>

                                        <tr>
                                            <td style="font-size: 14px;">l7_az</td>
                                            <td style="font-size: 14px;">PayPal</td>
                                            <td style="font-size: 14px;">This cookie is necessary for the PayPal
                                                login-function on the website.</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">1 day</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="card">
                    <div style="border: none;background: #eaeaea;cursor: pointer;" data-toggle="collapse"
                        data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                        class="card-header" id="headingOne">
                        <div style="float: left;">
                            <h2 class="mb-0">
                                <button
                                    style="padding: 0;color: #222222;text-decoration: none;font-size: 15px;font-weight: bold;"
                                    class="btn btn-link btn-block text-left" type="button">
                                    TARGETING COOKIES
                                </button>
                            </h2>
                        </div>

                        <div style="float: right;">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>

                    <div id="collapseFour" class="collapse" aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr style="border-bottom: 1px solid #2222222e;">
                                            <th style="font-size: 14px;" scope="col">Cookie Name</th>
                                            <th style="font-size: 14px;" scope="col">Service Provider</th>
                                            <th style="font-size: 14px;" scope="col">Purpose of Use</th>
                                            <th style="font-size: 14px;" scope="col">Type of Cookie</th>
                                            <th style="font-size: 14px;" scope="col">Storage Period</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px;">_fbp</td>
                                            <td style="font-size: 14px;">Facebook</td>
                                            <td style="font-size: 14px;">Used by Facebook to deliver a series of
                                                advertisement products such as real time bidding from third party
                                                advertisers</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">3 month</td>
                                        </tr>

                                        <tr>
                                            <td style="font-size: 14px;">ads/ga-audiences</td>
                                            <td style="font-size: 14px;">Google</td>
                                            <td style="font-size: 14px;">Used by Google AdWords to re-engage visitors
                                                that are likely to convert to customers based on the visitor's online
                                                behaviour across websites.</td>
                                            <td style="font-size: 14px;">3rd Party Cookie</td>
                                            <td style="font-size: 14px;">Session</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <h6 style="color: #fc7137;margin-top: 40px;">4. How to Control Cookies?</h6>
            <hr style="border-color: #fc7137;margin-top: 10px;margin-bottom: 10px;">

            <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                If you want to disable and personalize cookies, you can make the necessary settings on the internet
                browser and device you use. You can clear the cookie data stored on your device at any time until you
                disable cookies. You can edit your cookie usage permissions in the settings section of the browser you
                use, in line with your requests.
            </p>

            <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                If you refuse cookies, we would like to inform you that the Site may not function properly, may not
                fulfill some of its functions, may cause disruption in the provision of the services you request, and
                may cause you not to provide a special experience in the services provided.
            </p>
        </div>
    </div>
</div>

<div style="background: #ebebeb;">
    <div class="shippn-homepage-container container">
        <div class="row">
            <div class="col-md-12">
                <p style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;">
                    Browser manufacturers provide help pages for the management of cookies in their products. For
                    detailed information;
                </p>

                <p
                    style="color: black;font-size: 15px;font-weight: 300;margin-top: 20px;margin-bottom: 20px;word-break: break-all;">
                    <strong style="margin-right: 10px;">Google Chrome:</strong> <a target="_blank"
                        href="https://support.google.com/chrome/answer/95647?hl=en"
                        rel="external nofollow">https://support.google.com/chrome/answer/95647?hl=en</a><br>
                    <br>
                    <strong style="margin-right: 10px;">Mozilla Firefox:</strong> <a target="_blank"
                        href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                        rel="external nofollow">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer</a>
                    <br>
                    <br>

                    <strong style="margin-right: 10px;">Internet Explorer:</strong> <a target="_blank"
                        href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                        rel="external nofollow">https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a>
                    <br>
                    <br>

                    <strong style="margin-right: 10px;">Opera:</strong> <a target="_blank"
                        href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies"
                        rel="external nofollow">http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies</a>
                    <br>
                    <br>
                    <strong style="margin-right: 10px;">Opera Mobile:</strong> <a target="_blank"
                        href="http://www.opera.com/en/help/mobile/android#privacy"
                        rel="external nofollow">http://www.opera.com/en/help/mobile/android#privacy</a>
                    <br>
                    <br>

                    <strong style="margin-right: 10px;">Safari:</strong> <a target="_blank"
                        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                        rel="external nofollow">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
                    <br>
                    <br>
                    <strong style="margin-right: 10px;">Safari Mobile:</strong><a target="_blank"
                        href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a>
                    <br>
                    <br>
                    <strong style="margin-right: 10px;">Android Browser (Mobile):</strong><a target="_blank"
                        href="https://support.google.com/nexus/answer/54068?visit_id=1-636244844552607258-231308964&hl=en&rd=1"
                        rel="external nofollow">https://support.google.com/nexus/answer/54068?visit_id=1-636244844552607258-231308964&hl=en&rd=1</a>
                    <br>
                </p>
            </div>
        </div>
    </div>
</div>