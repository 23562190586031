import { ActionReducerMap } from '@ngrx/store';
import * as fromShopping from './../modules/shopping/store/shopping.reducer';
import * as fromAuth from './shared/auth/auth.reducers';
import * as fromShopperConsolidation from './shared/consolidation-shopper/consolidation.reducer';
import * as fromTimer from './shared/timer/timer.reducer';
import { TimerState } from './shared/timer/timer.reducer'; 
import * as fromPackageOrders from './shared/package-order/package-order.reducer';
import { PackageOrdersListState } from './shared/package-order/package-order.reducer';

export interface AppState {
    shoppingCart: fromShopping.ShoppingCartState;
    auth: fromAuth.AuthState;
    consolidationSh: fromShopperConsolidation.ConsolidationState;
    timer: TimerState;
    packageOrders: PackageOrdersListState;
}

export const appReducer: ActionReducerMap<AppState, any> = {
    shoppingCart: fromShopping.shoppingCartReducer,
    auth: fromAuth.authReducer,
    consolidationSh: fromShopperConsolidation.consolidationReducer,
    timer: fromTimer.timerReducer, 
    packageOrders: fromPackageOrders.packageOrdersReducer
};
