import { Component, OnInit } from '@angular/core';
import { SignupService } from '../../services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';
import { ToastrService } from 'ngx-toastr';
import { ShoppingBFMItem } from 'src/app/modules/shopping/shopping.model';
import * as fromApp from './../../store/app.reducer';
import * as fromShoppingCartActions from './../../modules/shopping/store/shopping.actions';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-perfume-store',
  templateUrl: './perfumeatelier.component.html',
  styleUrls: ['./perfumeatelier.component.css']
})
export class PerfumeAteilerStoreComponent implements OnInit {

  paramMemberID = '64db269bfd26fa0d0c51a17d';
  memberDetails;
  reviews = <any>[];
  pageOfItems: Array<any>;
  is_list;
  token;
  userID;
  elf_auth = 0;
  host_country;

  hostStoreProducts = [];

  anonymousID;
  cartID;
  constructor(
    public signUpService: SignupService,
    private watcherService: WatcherService,
    private toastr: ToastrService,
    private store: Store<fromApp.AppState>
  ) {
    this.paramMemberID = '64db269bfd26fa0d0c51a17d';
    this.signUpService.getHostMemberDetailsByID(this.paramMemberID).subscribe(data => {
      this.memberDetails = data;
      this.is_list = this.memberDetails.data.is_list;
      this.memberDetails.is_store_active = this.memberDetails['data']['is_store_active']

      this.elf_auth = this.is_list;
      this.host_country = data["data"]["country_code"];

      this.signUpService.getHostStoreProductWithID(this.token, this.paramMemberID).subscribe(response => {


        if (response['data'].length > 0) {
          this.hostStoreProducts = response['data'].filter(item => item.is_publish == 1);
          for (let index = 0; index < this.hostStoreProducts.length; index++) {
            this.hostStoreProducts[index].price = Number(this.hostStoreProducts[index].price).toFixed(2)
          }
        }
      })
    })
    this.signUpService.getHostMemberReviewsByID(this.paramMemberID).subscribe(data => {
      data["data"].forEach(rev => {
        this.reviews.push(rev.reviews)
      });
      this.reviews = this.reviews.flat(1);
      this.reviews.sort((a, b) => {
        if (a.date == undefined) {
          a.date = 0;
        }
        if (b.date == undefined) {
          b.date = 0;
        }
        if (a.date > b.date) {
          return -1;
        }
        if (a.date < b.date) {
          return 1;
        }
      })
    })
  }
  lang;
  bfmItems = [];
  bfmBasketActive: boolean = false;
  ngOnInit(): void {
    this.watcherService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    )
    this.store.select('auth').subscribe(state => {
      this.token = state.token;
      this.userID = state.user?.id;
    })
    this.signUpService.getUserByUserID(this.userID, this.token).subscribe(data => {
      this.elf_auth = +data["data"].right[0].elf_authorization;

    });
    this.anonymousID = localStorage.getItem('anonymous_id');
    this.cartID = localStorage.getItem('cart_id');
    if (this.anonymousID && this.cartID) {
      this.signUpService.getBFMDataFromCartID(this.cartID, this.anonymousID).subscribe(
        {
          next: (bfmDataFromCartResponse) => {
            if (bfmDataFromCartResponse['status'] === "OPEN") {
              this.watcherService.hostStoreShoppingCart(bfmDataFromCartResponse)
              this.bfmItems = bfmDataFromCartResponse['items'];
              this.bfmBasketActive = true;

              this.store.dispatch(fromShoppingCartActions.OpenShoppingCartSuccess({ anonymous_id: this.anonymousID, shopping_cart_id: this.cartID }))
            } else {
              this.anonymousID = '';
              this.cartID = '';
              localStorage.removeItem('anonymous_id');
              localStorage.removeItem('cart_id');
              this.bfmBasketActive = false;
            }
          },
          error: (err) => {
            this.anonymousID = '';
            this.cartID = '';
            localStorage.removeItem('anonymous_id');
            localStorage.removeItem('cart_id');
            this.bfmBasketActive = false;
          },
        }
      )
    }
  }
  checkDuplicateItem(storeItem) {
    var index = this.bfmItems.findIndex(x => x.url === storeItem.url && +x.price == +storeItem.price);
    return index;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;

  }
  domain_from_url(url) {
    var result
    var match
    if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
      result = match[1]
      if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
        result = match[1]
      }
    }
    return result
  }


  createBFMBasketWithStoreItem(storeItem) {
    let cartItems: ShoppingBFMItem = {
      currency_cd: 'USD',
      img: storeItem.images[0],
      is_required: '',
      price: +storeItem.price,
      productDescription: storeItem?.name,
      quantity: 1,
      source: storeItem.host_id,
      url: storeItem.url,
      vendor: storeItem.url ? this.domain_from_url(storeItem.url) : '',
      weight: "",
      product_id: 0,
      title: storeItem?.name
    }


    let itemsClone = [...this.bfmItems];
    let itemIndex = this.checkDuplicateItem(storeItem)
    if (itemIndex == -1) {
      itemsClone.push(cartItems)
    }
    else {
      // itemsClone[itemIndex].quantity += 1;
      itemsClone[itemIndex] = {
        ...itemsClone[itemIndex],
        quantity: itemsClone[itemIndex].quantity + 1
      };
    }

    let bfmOrderData = {
      items: itemsClone
    }


    if (this.bfmBasketActive || (this.anonymousID && this.cartID)) {
      this.signUpService.addBFMDataFromCartID(bfmOrderData, this.cartID, this.anonymousID).subscribe(data => {
        this.toastr.success("Product Added")
        this.watcherService.hostStoreShoppingCart(data);
        this.bfmItems = data['items'];
        this.store.dispatch(fromShoppingCartActions.UpdateBFMItemsFromAWS_({ bfm_items: (data['items'] as ShoppingBFMItem[]) }))
        localStorage.setItem('anonymous_id', this.anonymousID);
        localStorage.setItem('cart_id', this.cartID);

      })
    } else {
      this.signUpService.getAnonymousID('', { source: 'web' }).subscribe(
        response => {
          this.anonymousID = response['anonymous_id'];
          this.signUpService.getBFMShoppingCart('', { anonymous_id: this.anonymousID }).subscribe(data => {
            this.cartID = data['shopping_cart_id'];
            this.bfmBasketActive = true;
            this.signUpService.addBFMDataFromCartID({ items: [cartItems] }, this.cartID, this.anonymousID).subscribe(data => {

              this.store.dispatch(fromShoppingCartActions.OpenShoppingCartSuccess({ anonymous_id: this.anonymousID, shopping_cart_id: this.cartID }))
              this.store.dispatch(fromShoppingCartActions.UpdateBFMItemsFromAWS_({ bfm_items: (data['items'] as ShoppingBFMItem[]) }))
              localStorage.setItem('anonymous_id', this.anonymousID);
              localStorage.setItem('cart_id', this.cartID);

              this.toastr.success("Product Added");
              this.bfmItems = data['items'];
              this.watcherService.hostStoreShoppingCart(data)


            })
          })
        }
      )
    }
  }
}

