<div id="shippn-scroll" class="col-md-12 content-text {{textColorClass}} maxHeight-{{maxHeight}}">
    <p> 
        1. Shippn is a trusted community marketplace that allows users to shop and ship to anywhere in the world. Shippn hosts help shoppers from all around to world to have a local address in every country. The actual contract is directly between the host and the shopper. Shippn aims to bring hosts and shoppers together all around the world.
    </p>

    <p> 
        2. Shippn keeps the right to change, amend, cancel or make exceptions to the terms and conditions listed here. Please check this terms and conditions page from time to time. As long as a user has a membership and/or he/she continues to use Shippn services means that the user accepts and is subject to these terms and conditions.
    </p>

    <p> 
        3. By using this mail forwarding website and its services you are contracting with SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ., Atatürk Mh. Ertuğrul Gazi Sk. D2 Blok No: 2R İç Kapı No: 15 Ataşehir / İstanbul / 34758 / Türkiye. United States of America and you are considered that you accepted the following terms and conditions.
    </p>

    <p> 
        4. No agency, partnership, joint venture, or employment is created as a result of the Terms and Conditions and you do not have any authority of any kind to bind Shippn any respect whatsoever. Shippn shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond Shippn’s reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation.
    </p>

    <p> 
        5. The membership at Shippn starts with the registration. The use of mailing address provided by Shippn means that the member accepts the Terms and Conditions and authorizes Shippn to charge member’s credit card for all the services and fees occurred on Shippn.
    </p>

    <p> 
        6. The registration information you provide must be accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms and Conditions, which may result in immediate termination of your account.
    </p>

    <p> 
        7. Members are highly recommended to communicate with their hosts through the messaging system of Shippn only. Otherwise, Shippn is not liable for any off-site information exchange and cannot provide any support on related issues.
    </p>

    <p> 
        8. Hosts agree that site members are able to view their host page. If they need to limit their visibility, they can contact with us and request a reasonable adjustment to their visibility settings.
    </p>

    <p> 
        9. Shippn user if opted-in, agrees to get marketing promotions, campaign bulletins and all kinds of information and communication about the service by electronic mail to the registered e-mail address, and SMS to registered cell phone number. If the user wants, after logging in to his/her Shippn account, he/she can change his/her communication preferences on “My Account” page or he/she can contact Shippn by email or phone.
    </p>

    <p> 
        10. Shippn provides host’s addresses for its users. This address may be located in any city in the world.
    </p>

    <p> 
        11. Host receives members’ mail and packages at his/her stated residency, and forwards them to members’ local addresses during business hours. Shippn does not sell items to you. Shippn is not a hosting or a shipping company. It is a community that brings hosts and shoppers together and facilitates international shopping from and to any country.
    </p>

    <p> 
        12. Your payment to SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ.ludes hosting and shipping cost, and other services Shippn offers if defrayed by user.
    </p>

    <p> 
        13. All payments for all services, including extra handling, consolidation, repacking should go through Shippn, to official Shippn payment methods. This is for both the security of our hosts and shoppers. Making a payment directly to the hosts is not necessary in any case.
    </p>

    <p> 
        14. Shippn member is responsible for any and all charges and costs for receiving the mail and packages, any and all taxes or custom fees and any other related charges. Shippn member authorizes SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ. to charge his/her credit card for the above-mentioned taxes, shipping charges or fees. In the case of gifts or other purchases made on behalf of another recipient, Shippn member also agrees to grant the foregoing authorizations on behalf of the recipient designated in such order.
    </p>

    <p> 
        15. Members agree to keep their account password confidential and to protect it from unauthorized access. Members are responsible for any use of Shippn or Shippn account that happens under their login and password. If there is a suspicion that there’s been an unauthorized use of your Shippn account, you should immediately notify Shippn.
    </p>

    <p> 
        16. Shippn is concerned about the safety and privacy of all its users. However, it may access, preserve, and/or disclose your account information and/or Content if required to do so by law, or in a good faith belief that such access, preservation, or disclosure is reasonably necessary to (a) comply with legal process, (b) enforce the Terms and Conditions; (c) respond to claims that any Content violates the rights of third parties, (d) respond to your requests for customer services, or (e) protect the rights, property, or personal safety of Shippn, its users, and/or the public.
    </p>

    <p> 
        17. Shippn may share its users telephone number with its authorized service providers such as carriers. These service providers may contact you using autodialed or prerecorded calls and text messages, only as authorized by Shippn to carry out the purposes we have identified above. Shippn may, without further notice or warning and in its discretion, monitor or record telephone conversations that user or anyone acting on such user’s behalf has with Shippn or its agents for quality control and training purposes, or for its own protection. If Shippn provides you with information about another user, you agree you will use the information only for the purposes that it is provided to you. You may not disclose, sell, rent, or distribute a user’s information to a third party for purposes unrelated to the Shippn Services. Additionally, you may not use information for marketing purposes, via electronic or other means, unless you obtain the consent of the specific user to do so.
    </p>

    <p> 
        18. Shippn has no responsibility of links and files etc. (“Content”) entered or uploaded by its users.
    </p>

    <p> 
        19. Shipping charges are based on weight and volume of the package and will be calculated before member confirms shipment by clicking the “Confirm Order” button.
    </p>

    <p> 
        20. Shippn makes every effort to prevent any fraudulent activity within Shippn services and will cancel any account found to engage in such activity. Shippn requires document scans or photos from users to verify their identity to help protect other users from fraud. Shippn members and Shippn hosts must meet Shippn’s minimum performance standards disclosed on the Shippn web site from time to time. Failure to meet these standards may result in Shippn charging Shippn members and Shippn hosts additional fees, and/or limiting, restricting, suspending, or downgrading Shippn member's and/or Shippn host's account. If Shippn believes Shippn member and/or Shippn host is abusing Shippn in any way, Shippn may, in its sole discretion and without limiting other remedies, limit, suspend, or terminate the related user account(s) and access to Shippn Services, delay or remove hosted content, remove any special status associated with related account(s), remove and demote listings, reduce or eliminate any discounts, and take technical and/or legal steps to prevent the related user from using Shippn Services. Shippn may cancel unconfirmed accounts or accounts that have been inactive for a long time or modify or discontinue Shippn Services. Shippn also reserves the right to refuse or terminate all or part of Shippn Services to anyone for any reason at its own discretion.
    </p>

    <p> 
        21. Users should check, before the purchase, the website of Customs and Border Policies of their own countries and the country of the good which they wish to purchase. Certain countries may forbid export/import of specific goods or they may ask for a special permission/license to export/import such goods. In order to avoid legal action and fines, we recommend users and hosts to check competent rules and laws regularly, since Shippn cannot and will not take any liability in case of occurring problems about border controls.
    </p>

    <p> 
        22. Shippn.com  host stores members' mail and packages for 10 days for free. Extended periods of storage are charged $2 USD per week. Shippn hosts cannot store your mail or packages for more than 21 days. A storage period exceeded notice will be sent for the packages that exceeds 21 days.
    </p>

    <p> 
        23. If the shopper sends a package to a host without a corresponding or active order, a new order will be created on the shopper's account for the package. If the shopper does not confirm the order within 10 days, Shippn will charge the shopper $2 USD per week. The package will be disposed of if it remains unconfirmed for a maximum storage period of 21 days.'
    </p>

    <p> 
        24. Shipping rate calculation is based on the pure weight of the packages. Volumetric weight can also be taken in consideration for huge boxes. Volumetric weight is calculated by multiplying the dimensions (length, height and width in inches) of the packages and dividing the result by 166. If the volumetric weight is higher than the physical weight, shipping rate will be calculated using the volumetric weight.
    </p>

    <p> 
        25. Shippn may change fees and rates from time to time by posting the changes on the Shippn web site 14 days in advance, but with no advance notice required for temporary promotions or any changes that result in the reduction of fees.
    </p>

    <p> 
        26. Shipping insurance is calculated based on the declared value of the item. Insurance costs are shown to member before the member confirms the shipment by clicking on the "Confirm Order" button.
    </p>

    <p> 
        27. If a package is lost during transportation from host’s address to the shopper’s designated destination, Shippn’s liability is limited to the selected shipping courier’s liability which is specified by their terms and conditions.
    </p>

    <p> 
        28. Claims for damaged or lost shipments can be opened within 20 days after the carrier accepts the shipment, by contacting Shippn.
    </p>

    <p> 
        29. Shippn hosts open, inspect and repack as in the form of delivery all packages received, for security purposes. After such examination, in case the host considers the delivered goods are not in the countries' export/import lists, he/she may contact to the user directly via messaging service on Shippn. If parties cannot reach an agreement in 48 hours, they can involve Shippn in their case. Host keeps the right to inform competent authorities, if he/she finds goods deemed illegal for his/her country's applicable law.
    </p>

    <p> 
        30. Shippn cannot be held liable for the unlawful acts of hosts and users.
    </p>

    <p> 
        31. Both parties have the right to cancel this agreement via written notice (e-mail, fax message or mail).
    </p>

    <p>
        32. Shippn has the right to ask hosts to send the packages back to sellers or dispose them for security reasons. The shipping rates of the packages will be double of normal rates for the users who cancelled their membership and want to ship packages after cancellation.
    </p>

    <p>
        33. If the user does not make the payment, or completes verification over 20 days, for the Shippn services that he/she used, Shippn keeps the right to cancel the user's membership. Shippn has the right to send the packages back to sellers or dispose of them. In this case, the user would not have the option to claim a right over these packages.
    </p>

    <p>
        34. Overdue fees: There is $10 overdue fee and %9 interest for late payment over 30 days of the due date of the services used. The interest will be charged for each month that the payment is not made.
    </p>

    <p>
        35. If the user chooses to abandon their shipment in case of non-delivery, the package will be treated as abandoned shipment if the user refuses delivery, incorrect address, refuse to pay customs and taxes. The user would not have the option to claim over the package and shipping charges. If the user chooses to return the shipment in case of non-delivery, The return shipping fee will be charged to the user's registered payment method.
    </p>

    <p>
        36. Shippn keeps the right to change, amend, cancel or make exceptions on the prices listed here.
    </p>

    <p>
        37. Users are liable for their return shipments (if necessary), duties and taxes, and all other associated fees (if mandatory). Reasons for return shipments may be refusing delivery for any reason, incorrect address, rejection of restricted items by user's country, unsuccessful delivery by carrier because of not being at delivery address or refuse to pay duties and taxes, or a return request by user for any reason.
    </p>

    <p>
        38. Shippn.com member agrees to obey all the laws and regulation of both U.S. and his/her country. Shippn member cannot and will not use this site for fraudulent transactions or shipments or for shipping illegal or controlled substances. Shippn member will be responsible for any charges or fees resulting from a violation of this condition.
    </p>

    <p>
        39. Members may not use the Shippn website or host's services for any unlawful purpose. Shippn have the right to restrict member's access to his/her Shippn account and use of the services, if there is a use of offend upon another person's rights, violate any laws, or breach these Terms and Conditions.
    </p>

    <p>
        40. Shippn is not liable for not fulfilling any obligations towards members at all as a result of circumstances beyond our control such as (but not limited to); acts of god including earthquakes, cyclones, storms, flooding, fire, disease, fog, snow or frost; force majeure including (but not limited to) war, accidents, acts of public enemies, strikes, embargoes, perils of the air, local disputes or civil commotions; national or local disruptions in air or ground transportation networks and mechanical problems to modes of transport or machinery; latent defects or inherent vice in the contents of the shipment; criminal acts of third parties such as theft and arson; electricity blackouts, network or satellite connection breaks.
    </p>

    <p>
        41. By accepting these Terms and Conditions, you also accept that Shippn is acting as intermediary agent and is not responsible for any and all actions of Shippn members and/or Shippn hosts or the web sites that Shippn members will shop from. Accordingly, Shippn excludes all express or implied warranties, terms and conditions including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
    </p>

    <p>
        42. If you have a dispute with one or more users, you release us (and our affiliates and subsidiaries, and our and their respective officers, directors, employees and agents) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. In entering into this release, you expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.
    </p>

    <p>
        43. You will indemnify and hold Shippn (including its affiliates and subsidiaries, as well as its and their respective officers, directors, employees, agents) harmless from any claim or demand, including reasonable legal fees, made by any third party due to or arising out of your breach of this Agreement, your improper use of Shippn Services or your breach of any law or the rights of a third party.
    </p>

    <p>
        44. Restricted and cannot be shipped items may depend on user's destination country. User is responsible to know and act through rules, laws, regulations, and restrictions and/or limitations of his/her destination country. Shipping chemicals, flammable, explosive and/or sharp items, cash, weapons, goods for reselling are strictly prohibited on Shippn. Additionally, cartridges and toners over 500 grams cannot be shipped via express cargo due to the international cargo rule amendments which took effect by December, 2010.
    </p>

    <p>
        45. In order to protect the community, Shippn keeps the right to apply penalties to its hosts in the case of damage to the packages or shipments. Hosts are responsible for packages and products during the process of receiving them from the courier company to delivering them to the courier company. Hosts are obliged to fully cover the damage they cause. The total cost of the product including shipping costs or any loss caused by the damage on the product may be charged to host's credit card.
    </p>

    <p>
        46. Measuring the weight and the dimension of the package correctly is a responsibility of the host. Incorrect measurement of packages or incorrect submission of information about package details on Shippn system is sole responsibility of hosts and may cause penalties.
    </p>

    <p>
        47. If any provision of this Terms and Conditions is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that the Terms and Conditions shall otherwise remain in full force and effect and enforceable. Shippn may transfer, assign or delegate the Terms and Conditions and its rights and obligations without consent.
    </p>

    <p>
        48. The laws of New Jersey, United States of America, without regard to principles of conflict of laws, exclusively applies and governs to all contracts concluded by us based on these Terms and Conditions and to any further kind of claims arising thereof.
    </p>

    <p>
        49. The place of fulfillment and jurisdiction shall be New Jersey, United States of America.
    </p>
    
</div>