<!-- 
<div class="countdown-container" style="background-color: #fff; color:#0f0f0f">
    <div [innerHTML]="announcemenetSTR"></div>
</div> -->

<!-- <div class="countdown-container"> -->



<!-- <ng-container *ngIf="timer$ |async as timer">
        <div class="content" *ngFor="let i of [0,1,2,3,4,5]">
          <div style="position:absolute">
              <div class="flip">
                  <div class="up">
                      <div>{{timer[i].value}}</div>
                  </div>
                  <div class="down">
                      <div>
                          <div>{{timer[i].old}}</div>
                      </div>
                  </div>
              </div>
          </div>
          <div style="position:absolute">
              <div class="flip-card">
                  <div class="flip-card-inner" [@flip]="timer[i].value" (@flip.done)="timer[i].old=timer[i].value" >
                      <div class="flip-card-front">
                          <div class="up">
                              <div>{{timer[i].old}}</div>
                          </div>
                      </div>
                      <div class="flip-card-back">
                          <div class="down">
                              <div>
                                  <div>{{timer[i].value}}</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      
      </ng-container> -->
<!-- </div> -->

<div class="banner-with-countdown" (click)="openAnnouncementUrl()" *ngIf="announcementData?.status == 1"
[ngClass]="{'cursorPointer': announcementData?.url}" #div (window:resize)="elHeight = div.getBoundingClientRect()">

    
    <div class="banner-content-wrapper" [innerHTML]="announcemenetHTML">
    </div>


    <div class="countdown-wrapper" *ngIf="announcementData.countdown == '1'">
        <div class="countdown-item countdown-days">
            <div class="countdown-item-value">
                <span *ngIf="leftDays < 10">0</span>{{leftDays}}
            </div>
            <div class="countdown-item-label">
                Day<span *ngIf="leftDays > 1">s</span>
            </div>
        </div>
        <div class="countdown-item countdown-hours">
            <div class="countdown-item-value">
                <span *ngIf="leftHours < 10">0</span>{{leftHours}}
            </div>
            <div class="countdown-item-label">
                Hour<span *ngIf="leftHours > 1">s</span>
            </div>
        </div>
        <div class="countdown-item countdown-minutes">
            <div class="countdown-item-value">
                <span *ngIf="leftMinutes < 10">0</span>{{leftMinutes}}
            </div>
            <div class="countdown-item-label">
                Min<span *ngIf="leftMinutes > 1">s</span>
            </div>
        </div>
        <div class="countdown-item countdown-seconds">
            <div class="countdown-item-value">
                <span *ngIf="leftSeconds < 10">0</span>{{leftSeconds}}
            </div>
            <div class="countdown-item-label">
                Sec<span *ngIf="leftSeconds > 1">s</span>
            </div>
        </div>

    </div>
</div>