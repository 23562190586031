import { createAction, props } from "@ngrx/store";
import { ShoppingCartProduct, ShoppingBFMItem } from './../shopping.model';

export enum ShoppingActionTypes {
    Add = '[ShoppingCart] Shopping Product Added to Cart',
    Remove = '[ShoppingCart] Shopping Product Remove from Cart',
    ListProducts = '[ShoppingCart] Listing Shopping Products',
    OpenCart = '[ShoppingCart] Open Cart Create Anonymous',
    CreateCartID = '[ShoppingCart] Create Cart ID',
    OpenCartSuccess = '[ShoppingCart] Open Cart Success',
    ClearCart = '[ShoppingCart] Clear Cart',
    CloseCart = '[ShoppingCart] Close Cart',
    ConvertProductsToBFMItems = '[ShoppingCart] Shopping Converts Products To BFM Items',
    ProductsConvertedBFMItems = '[ShoppingCart] Shopping Products Converted To BFM Items',
    UpdateBFMItemsFromAWS = '[ShoppingCart] Update BFM Items From AWS'
}

export const CartCleared = createAction(
    ShoppingActionTypes.ClearCart
)

export const ProductAdded = createAction(
    ShoppingActionTypes.Add,
    props<{ shoppingProduct: ShoppingCartProduct}>(),
)

export const ConvertProducts = createAction(
    ShoppingActionTypes.ConvertProductsToBFMItems,
    props<{products: ShoppingCartProduct[]}>()
)
export const ConvertedProducts = createAction(
    ShoppingActionTypes.ProductsConvertedBFMItems,
    props<{bfm_items: ShoppingBFMItem[]}>()
)

export const ProductRemoved = createAction(
    ShoppingActionTypes.Remove,
    props<{ shoppingProduct: ShoppingCartProduct}>(),
)

export const ProductsListed = createAction(
    ShoppingActionTypes.ListProducts
)

export const CloseCart = createAction(
    ShoppingActionTypes.CloseCart,
    props<{status: string}>()
)
export const OpenShoppingCart = createAction(
    ShoppingActionTypes.OpenCart
)
export const CreateShoppingCartID = createAction(
    ShoppingActionTypes.CreateCartID,
    props<{anonymous_id: string}>()
)
export const OpenShoppingCartSuccess = createAction(
    ShoppingActionTypes.OpenCartSuccess,
    props<{ anonymous_id: string, shopping_cart_id: string }>()
)

export const UpdateBFMItemsFromAWS_ = createAction(
    ShoppingActionTypes.UpdateBFMItemsFromAWS, 
    props<{ bfm_items: ShoppingBFMItem[]}>()
)