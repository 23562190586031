import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimThis'
})
export class TrimPipe implements PipeTransform {

  transform(value: string): string {
    // trim() not worked
    return value.replace(/\s/g, "")
}

}