import { Injectable } from "@angular/core";
@Injectable({providedIn: 'root'})
export class StripeService{
    constructor() {}
    checkScript(){}

    createScript(){
        if(window.document.getElementById('stripeSource')){
            return; 
        } else {
            const stripeScriptUrl = "https://js.stripe.com/v3/";
            const scriptEl = window.document.createElement('script');
            scriptEl.src = stripeScriptUrl;
            scriptEl.id = 'stripeSource'
            window.document.body.appendChild(scriptEl);
        }
        
    }
}