<div class="instant-calculator-discount-count-down">




    <div class="countdown-timer-container">
        <div class="countdown-timer">
          {{ countdownMinutes | number: '2.0-0' }}:{{ countdownSeconds | number: '2.0-0' }}
        </div>
      </div>


</div>