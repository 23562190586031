// timer.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as TimerActions from './timer.actions';

export interface TimerState {
  minutes: number;
  seconds: number;
  started: boolean;
  finished: boolean;
}

const initialState: TimerState = {
  minutes: 1,
  seconds: 0,
  started: false,
  finished: false
};

export const timerReducer = createReducer(
  initialState,
  on(TimerActions.startTimer, state => ({ ...state, started: true })),
  on(TimerActions.updateRemainingTime, state => {
    const newSeconds = state.seconds === 0 ? 59 : state.seconds - 1;
    const newMinutes = newSeconds === 59 ? state.minutes - 1 : state.minutes;
    return { ...state, minutes: newMinutes, seconds: newSeconds };
  }),
  on(TimerActions.finishTimer, state => ({ ...state, finished: true }))
);
