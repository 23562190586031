import { Component, OnInit, ViewEncapsulation, HostListener, HostBinding, ElementRef, AfterViewInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';

@Component({
    selector: 'app-instant-calculator-discount-countdown',
    templateUrl: './instant-calculator-discount-countdown.component.html',
    styleUrls: ['./instant-calculator-discount-countdown.component.css']
})
export class InstantCalculatorDiscountCountdownComponent implements OnInit, AfterViewInit {

    countdownMinutes: number = 10;
    countdownSeconds: number = 0;
    countdownInterval: any;


    constructor(private signUpService: SignupService, private el: ElementRef, private watcherService: WatcherService) {
    }

    ngOnInit(): void {
        this.startCountdown();

    }
    ngAfterViewInit(): void {
    }

    startCountdown() {
        this.countdownInterval = setInterval(() => {
            if (this.countdownSeconds === 0) {
                if (this.countdownMinutes === 0) {
                    clearInterval(this.countdownInterval);
                    // Countdown finished, you can perform any actions here
                } else {
                    this.countdownMinutes--;
                    this.countdownSeconds = 59;
                }
            } else {
                this.countdownSeconds--;
            }
        }, 1000);
    }


}
