<div class="sticky-action-bar" *ngIf="!hello10Variant">
    <ul class="actions">
        <li class="action" *ngIf="loggedInUserType == 1">
            <button (click)="goToBasket()">
                <span class="basket-product-count" *ngIf="shoppingCartItems.length > 0" translate="no">
                    {{shoppingCartItems.length}}
                </span>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <path class="cls-2"
                        d="m6.21,5.52c-.38,0-.69.31-.69.69s.31.69.69.69v-1.38Zm2.44.69l.67-.17c-.08-.31-.35-.52-.67-.52v.69Zm13.19,13.38c.38,0,.69-.31.69-.69s-.31-.69-.69-.69v1.38Zm1.95-11.43l.67.17c.05-.21,0-.42-.13-.59s-.33-.26-.54-.26v.68Zm-1.95,7.81v.69c.32,0,.59-.21.67-.52l-.67-.17ZM6.21,6.9h2.44v-1.37h-2.44v1.37Zm1.77-.53l.49,1.96,1.33-.33-.49-1.96-1.33.33Zm.49,1.96l1.95,7.81,1.33-.33-1.96-7.81-1.32.33Zm2.62,6.96h-.49v1.37h.49v-1.37Zm-.48,4.3h11.23v-1.38h-11.23v1.38Zm-2.16-2.15c0,1.19.96,2.15,2.15,2.15v-1.38c-.43,0-.78-.35-.78-.78h-1.37Zm2.16-2.15c-1.19,0-2.15.96-2.15,2.15h1.38c0-.43.35-.78.78-.78v-1.37h0Zm-1.47-6.44h14.64v-1.37h-14.64v1.37Zm13.98-.85l-1.96,7.81,1.33.33,1.96-7.81-1.33-.33Zm-1.29,7.29h-10.74v1.37h10.74v-1.37Zm-.69,7.52c0,.16-.13.29-.29.29v1.38c.92,0,1.66-.75,1.66-1.66h-1.37Zm-.29.29c-.16,0-.29-.13-.29-.29h-1.38c0,.92.75,1.66,1.66,1.66v-1.37h0Zm-.29-.29c0-.16.13-.29.29-.29v-1.37c-.92,0-1.66.75-1.66,1.66h1.37Zm.29-.29c.16,0,.29.13.29.29h1.37c0-.92-.75-1.66-1.66-1.66v1.37Zm-9.48.29c0,.16-.13.29-.29.29v1.38c.92,0,1.66-.75,1.66-1.66h-1.37Zm-.29.29c-.16,0-.29-.13-.29-.29h-1.38c0,.92.75,1.66,1.66,1.66v-1.37h0Zm-.29-.29c0-.16.13-.29.29-.29v-1.37c-.92,0-1.66.75-1.66,1.66h1.37Zm.29-.29c.16,0,.29.13.29.29h1.37c0-.92-.75-1.66-1.66-1.66v1.37Z" />
                </svg>
                <span>Basket</span>
            </button>
        </li>
        <!-- Sign Up-->
        <li class="action" *ngIf="!isUserLogin">
            <button (click)="goToSignUp()">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <g>
                        <g>
                            <g>
                                <path class="cls-2"
                                    d="m15.1,15.21c2.67,0,4.84-2.17,4.84-4.84s-2.17-4.84-4.84-4.84-4.84,2.17-4.84,4.84,2.17,4.84,4.84,4.84Zm0-8.23c1.87,0,3.39,1.52,3.39,3.39s-1.52,3.39-3.39,3.39-3.39-1.52-3.39-3.39,1.52-3.39,3.39-3.39Z" />
                                <path class="cls-2"
                                    d="m20.02,23.02h-11.4v-.99c0-1.52,1.19-2.7,2.62-2.7h6.74c-.04-.28-.09-.56-.09-.84,0-.21.04-.41.06-.61h-6.72c-2.26,0-4.06,1.87-4.06,4.16v1.72c0,.4.33.73.73.73h14.41c.14,0,.27-.05.38-.12-1.01-.21-1.91-.69-2.67-1.33Z" />
                            </g>
                            <path class="cls-2"
                                d="m23.88,23.04c-2.52,0-4.57-2.05-4.57-4.57s2.05-4.57,4.57-4.57,4.57,2.05,4.57,4.57-2.05,4.57-4.57,4.57Zm0-7.88c-1.83,0-3.32,1.49-3.32,3.32s1.49,3.32,3.32,3.32,3.32-1.49,3.32-3.32-1.49-3.32-3.32-3.32Z" />
                            <path class="cls-2"
                                d="m23.88,20.99c-.35,0-.65-.29-.65-.65v-3.74c0-.35.29-.65.65-.65s.65.29.65.65v3.74c0,.35-.29.65-.65.65Z" />
                            <path class="cls-2"
                                d="m25.75,19.12h-3.74c-.35,0-.65-.29-.65-.65s.29-.65.65-.65h3.74c.35,0,.65.29.65.65s-.29.65-.65.65Z" />
                        </g>
                    </g>
                </svg>
                <span>Sign Up</span>
            </button>
        </li>
        <!-- Sign Up-->
        <li class="action" *ngIf="isUserLogin && loggedInUserType != 4">
            <button (click)="openCreateOrderModal()">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <g>
                        <path class="cls-2"
                            d="m23.16,13.78v6.93l-7.8,3.9v-7.64l3.75-1.87v2.48c0,.36.29.66.66.66s.66-.29.66-.66v-3.14l1.91-.96c-.47-.22-.89-.5-1.28-.83l-1.29.64-7.64-3.82,2.58-1.29,4.77,2.39c-.23-.52-.36-1.09-.43-1.68l-4.05-2.03-.29-.15-.29.15-9.11,4.56-.36.18v9.93l.36.18,9.11,4.56.29.15.29-.15-.23-.45h0l.23.45,9.11-4.56-.29-.59h0l.29.59.36-.18v-7.52c-.46-.02-.89-.1-1.32-.22Zm-9.11,10.83l-7.8-3.9v-7.64l7.8,3.9v7.64Zm.66-8.79l-7.64-3.82,3.59-1.8,7.64,3.82-3.59,1.8Z" />
                        <g>
                            <path class="cls-2"
                                d="m24.76,12.94c-2.58,0-4.68-2.1-4.68-4.68s2.1-4.68,4.68-4.68,4.68,2.1,4.68,4.68-2.1,4.68-4.68,4.68Zm0-8.06c-1.87,0-3.4,1.52-3.4,3.4s1.52,3.4,3.4,3.4,3.4-1.52,3.4-3.4-1.52-3.4-3.4-3.4Z" />
                            <path class="cls-2"
                                d="m24.76,10.84c-.36,0-.66-.3-.66-.66v-3.83c0-.36.3-.66.66-.66s.66.3.66.66v3.83c0,.36-.3.66-.66.66Z" />
                            <path class="cls-2"
                                d="m26.67,8.93h-3.83c-.36,0-.66-.3-.66-.66s.3-.66.66-.66h3.83c.36,0,.66.3.66.66s-.3.66-.66.66Z" />
                        </g>
                    </g>
                </svg>

                <span>
                    Create
                </span>
            </button>
        </li>
        <li class="action contact">
            <button (click)="openWp()">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <g>
                        <path class="cls-wp"
                            d="m24.4,14.52c-.13-4.95-4.2-8.93-9.22-8.93s-9,3.88-9.22,8.76c0,.13,0,.27,0,.4,0,1.73.48,3.35,1.32,4.73l-1.66,4.91,5.11-1.62c1.32.73,2.84,1.14,4.46,1.14,5.1,0,9.23-4.1,9.23-9.16,0-.08,0-.16,0-.23Zm-9.22,7.93c-1.58,0-3.04-.47-4.27-1.27l-2.98.95.97-2.86c-.93-1.27-1.48-2.83-1.48-4.52,0-.25.01-.5.04-.75.38-3.9,3.7-6.95,7.72-6.95s7.42,3.13,7.73,7.1c.02.2.03.4.03.6,0,4.25-3.48,7.7-7.76,7.7Z" />
                        <path class="cls-wp"
                            d="m19.4,16.61c-.23-.11-1.34-.66-1.55-.73-.21-.08-.36-.11-.51.11-.15.22-.59.73-.72.88-.13.15-.26.17-.49.06-.22-.11-.95-.35-1.82-1.11-.67-.59-1.13-1.33-1.26-1.55-.13-.22-.01-.35.1-.46.1-.1.22-.26.34-.39.03-.04.06-.07.08-.11.05-.08.09-.16.14-.27.08-.15.04-.28-.02-.39-.06-.11-.51-1.22-.7-1.67-.19-.45-.38-.37-.51-.37s-.28-.02-.43-.02-.4.06-.6.28c-.21.22-.79.77-.79,1.88,0,.26.05.52.12.76.23.79.72,1.44.81,1.56.11.15,1.56,2.49,3.86,3.39,2.3.89,2.3.6,2.72.56.41-.03,1.34-.54,1.53-1.07.19-.52.19-.97.13-1.07-.06-.09-.21-.15-.43-.26Z" />
                    </g>
                </svg>
                <span>Contact</span>
            </button>
        </li>
    </ul>
</div>