import { createReducer, on } from "@ngrx/store";
import * as PackageOrdersActions from "./package-order.actions";
import { packageOrder } from "./package-order.model";
export interface PackageOrdersListState {
    status: number | null;
    packageOrders: packageOrder[] | null;
}
const packageOrdersInitialState: PackageOrdersListState = {
    status: null,
    packageOrders: null,
}

export const packageOrdersReducer = createReducer(
    packageOrdersInitialState,
    on(
        PackageOrdersActions.CreatePackageOrderList, (state, action) => {
            return {
                ...state,
                status: 1,
                packageOrders: action.packageOrdersList
            }
        }),
    on(
        PackageOrdersActions.DeletePackageOrderList, (state, action) => {
            return {
                ...state,
                status: 0,
                packageOrders: null
            }
        }),
    on(
        PackageOrdersActions.UpdatePackageOrderList, (state, action) => {
            return {
                ...state,
                status: 1,
                packageOrders: action.packageOrdersList
            }
        }),
    on(
        PackageOrdersActions.AddPackageOrderToList, (state, action) => {
            const packageOrderListClone = state.packageOrders;
            const found = packageOrderListClone.find(e => e.url == action.packageOrder.url);
            if (found) {
                return state;
            } else {
                return {
                    ...state,
                    status: 1,
                    packageOrders: [...state.packageOrders, action.packageOrder]
                }
            }
        }
    ),
    on(
        PackageOrdersActions.RemovePackageOrderFromList, (state, action) => {
            const packageOrderListClone: packageOrder[] = JSON.parse(JSON.stringify(state.packageOrders));
            const found = packageOrderListClone.find(e => e.url == action.packageOrder.url);
            if (found) {
                if (packageOrderListClone.length > 1) {
                    const index = packageOrderListClone.indexOf(found);
                    packageOrderListClone.splice(index, 1);
                    return {
                        ...state,
                        status: 1,
                        packageOrders: packageOrderListClone
                    }
                } else {
                    return {
                        ...state,
                        status: 0,
                        packageOrders: null
                    }
                }
            } else {
                return state;
            }
        })
)