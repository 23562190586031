<div style="font-family: 'Roboto'; min-height: 100vh; width: 100vw;">
  <div style="position: relative; overflow: hidden;"
    class="homepage-header-bg commerical-banner-background-color shippn-main-banner-swiper-wrapper">

    <div>
      <swiper-container class="col-md-12 shippn-swiper-slider-wrapper-swiper shippn-main-banner-swiper-swiper-container"
        loop="true" pagination="true" pagination-clickable="true" autoplay-delay="10000"
        autoplay-disable-on-interaction="false" id="shippn-main-banner-swiper" keyboard="true">
        <swiper-slide class="main-banner-slide-first more-than-35-countries">
          <div class="container padding-0">
            <div class="main-banner-text">
              <h1>
                <span class="size-standard-h1 color-banner-orange">Unlock Borderless Shopping</span>
                <span class="size-small-h1 color-banner-black">From More Than</span>
                <span class="size-big-h1 color-banner-black">{{hostCountriesCount}} Countries</span>
              </h1>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide class="main-banner-slide-second shop-internationally-bg">
        </swiper-slide>
      </swiper-container>
    </div>

  </div>

  <div class="container shippn-homepage-container">
    <div class="row">
      <div class="col-12">

        <div class="buy-for-me-start-wrapper">
          <div class="buy-for-me-figure">
            <img style="width: 100%;" src="/assets/img/buyforme/buyforme-image-1-alt.webp"
              alt="Shippn Buy for me (BFM) service explanation image, collage shoes and Women">
          </div>
          <div class="buy-for-me-form">
            <h4>Buy For Me Service</h4>
            <h6>Copy and paste the link of the product you want to purchase and we buy it for you!</h6>
            <form ngNativeValidate [formGroup]="bfmMailForm">
              <input placeholder="Example: https://www.ebay.com/itm/Original-Silicone-var=564232150986"
                formControlName="bfmMail"
                [ngClass]="{'shippn-border-red':bfmMailForm.get('bfmMail').touched && bfmMailForm.get('bfmMail').invalid}"
                style="margin-top: 5px;margin-bottom: 5px;" type="text" class="form-control shippn-input">
            </form>
            <div style="padding-bottom: 10px;" class="shippn-from-to-area-go-btn-area">
              <button style="margin-top: 5px;font-size: 14px; cursor: pointer;padding:8px 24px!important;" (click)="goBFM()"
                class="btn shippn-from-to-area-go-btn croBFMBtn" id="welcomeBFMBtn">Buy
                Now</button>
            </div>
            <div>
              <a class="buy-for-me-form-a croShopFromBtn" [routerLink]="['/', lang,'shop-from']" id="welcomeShopFromBtn">I want to buy products myself</a>
            </div>

          </div>
        </div>



      </div>
    </div>
  </div>
  <!-- Shippn Coridor -->
  <!-- Swiper -->
  <div class="shippn-homepage-container container" style="margin-top: 20px;">
    <div class="row">
      <div class="col-md-12">
        <h4 class="header-underline"
          style="text-align: center;font-size: 26px;font-weight: bold;margin-bottom: 40px;margin-top: 50px;">
          TOP RATED ROUTES
        </h4>
      </div>
    </div>
    <div class="row shippn-swiper-slider-wrapper">
      <swiper-container class="col-md-12 shippn-swiper-slider-wrapper-swiper" loop="true"
        id="shippn-rated-routes-swiper">
        <swiper-slide *ngFor="let route of topRatedRouteList; let i = index; trackBy:trackByIndex;">
          <div class="top-rated-route">
            <div class="route-from">
              <img fetchpriority="low" width="20" height="20" loading="lazy" decoding="auto"
                class="completed-purchase-countries-flag" src="{{route.routeFromFlag}}"
                alt="{{route.routeFromName}} flag">
              <span class="route-country-label">From </span>
              <span>{{route.routeFromName}} </span>
            </div>
            <div class="route-to">
              <img fetchpriority="low" width="20" height="20" loading="lazy" decoding="auto"
                class="completed-purchase-countries-flag" src="{{route.routeToFlag}}" alt="{{route.routeToName}} flag">
              <span class="route-country-label">To </span>
              <span>{{route.routeToName}} </span>
            </div>
            <div class="route-starting-price">
              <span>{{route.routePrice}}<span style="font-size: 15px;">/per kg</span></span>
              <span class="route-starts-from">Based on 10kg parcel</span>
            </div>
            <div class="route-call-to-action">
              <button class="btn shippn-from-to-area-go-btn route-get-address" aria-labelledby="Get Your Address"
                [routerLink]="['/',lang,'shop-from', route.routeFrom]"
                [queryParams]="{source: 'home_trr'}">Get
                your address</button>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <button id="topRoute-prev" aria-label="Top Routes Slider Prev"
        class="swiper-navigation-custom-btn swiper-custom-btn-prev"><i class="fas fa-chevron-left"></i></button>
      <button id="topRoute-next" class="swiper-navigation-custom-btn swiper-custom-btn-next"
        aria-label="Top Routes Slider Next"><i class="fas fa-chevron-right"></i></button>

    </div>
  </div>
  <!-- Swiper -->
  <!-- Shippn Coridor -->

  <!-- Hottest Products -->
  <!-- Swiper -->
  <div class="shippn-homepage-container container" style="margin-top: 20px;">
    <div class="row">
      <div class="col-md-12">
        <h4 class="header-underline"
          style="text-align: center;font-size: 26px;font-weight: bold;margin-bottom: 40px;margin-top: 50px;">
          HOTTEST PRODUCTS
        </h4>
      </div>
    </div>
    <div class="row shippn-swiper-slider-wrapper">
      <swiper-container class="col-md-12 shippn-swiper-slider-wrapper-swiper" loop="true"
        id="shippn-hottest-products-swiper">
        <swiper-slide *ngFor="let item of bfmPurchaseList; trackBy:trackByIndex">
          <div class="shopping-product-swiper-wrapper">
            <div class="shopping-product">
              <div class="shopping-product-image">
                <img fetchpriority="low" src="{{item.items[0].img}}" alt="{{item.items[0].title}}" async loading="lazy"
                  decoding="auto">
              </div>
              <div class="shopping-product-meta" style="width: 100%;">
                <div class="shoppin-product-brand"
                  style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                  {{item.items[0].title}}
                </div>
                <div class="shopping-product-unBrandedName">
                  <span class="completed-purchase-countries">From </span>
                  <img class="completed-purchase-countries-flag" width="20" height="20" loading="lazy" decoding="auto"
                    src="https://www.shippn.com/assets/img/flags/{{item?.country_from_flag}}"
                    alt="{{item.country_from_name}} flag" fetchpriority="low">
                  <span class="completed-purchase-countries">{{item.country_from_name}}
                  </span>
                </div>
                <div class="shopping-product-unBrandedName">
                  <span class="completed-purchase-to-countries">To
                  </span>
                  <img class="completed-purchase-countries-flag" width="20" height="20" loading="lazy" decoding="auto"
                    src="https://www.shippn.com/assets/img/flags/{{item?.country_to_flag}}"
                    alt="{{item.country_to_name}} flag" fetchpriority="low">
                  <span class="completed-purchase-countries">{{item.country_to_name}}
                  </span>
                </div>
              </div>
            </div>
            <div class="shopping-product-overlay">
              <a [routerLink]="['/', lang, 'bfm', 'latest-purchases', item.id]"
                class="btn shippn-from-to-area-go-btn">Buy Now</a>
            </div>
          </div>


        </swiper-slide>
      </swiper-container>
      <button id="hottestProducts-prev" class="swiper-navigation-custom-btn swiper-custom-btn-prev"
        aria-label="Hottest Products Sliver Prev"><i class="fas fa-chevron-left"></i></button>
      <button id="hottestProducts-next" class="swiper-navigation-custom-btn swiper-custom-btn-next"
        aria-label="Hottest Products Slider Next"><i class="fas fa-chevron-right"></i></button>

    </div>
  </div>
  <!-- Swiper -->
  <!-- Hottest Products -->
  <div class="shippn-homepage-container container">
    <div class="row">
      <div class="col-md-12">
        <h4 class="header-underline"
          style="text-align: center;font-size: 26px;font-weight: bold;margin-bottom: 27px;margin-top: 50px;">HOW
          SHIPPN WORKS?</h4>
      </div>
    </div>
    <div style="margin-top: 35px;" class="row">
      <div style="text-align: center;" class="col-md-4">
        <img fetchpriority="low" style="width: 40px;" async src="../assets/img/address.webp" alt="Address Icon"
          loading="lazy" decoding="auto" width="40px" height="40px">
        <h4 style="text-align: center;font-size: 18px;font-weight: bold;margin-bottom: 12px;margin-top: 10px;">GET AN
          ADDRESS</h4>
        <p style="font-size: 14px;line-height: 20px;">Choose one of Shippn hosts in the country you want to shop from.
          No sign-up or set up fees.</p>
      </div>
      <div style="text-align: center;" class="col-md-4">
        <img fetchpriority="low" style="width: 40px;" async src="../assets/img/smartphone.webp" alt="Smartphone Icon"
          loading="lazy" decoding="auto" width="40px" height="40px">
        <h4 style="text-align: center;font-size: 18px;font-weight: bold;margin-bottom: 12px;margin-top: 10px;">SHOP FROM
          ANY STORE</h4>
        <p style="font-size: 14px;line-height: 20px;">Use your host's address as your
          delivery address at checkout.</p>
      </div>
      <div style="text-align: center;" class="col-md-4">
        <img fetchpriority="low" style="width: 40px;" async src="../assets/img/package.webp" alt="Package, box icon"
          loading="lazy" decoding="auto" width="40px" height="36px">
        <h4 style="text-align: center;font-size: 18px;font-weight: bold;margin-bottom: 12px;margin-top: 10px;">RECEIVE
          YOUR ITEMS</h4>
        <p style="font-size: 14px;line-height: 20px;">Receive your packages with trusted
          logistics partners, DHL, TNT, UPS,
          and FedEx. Save up to 70% on
          shipping</p>
      </div>
      <div style="text-align: center;margin-top: 20px;" class="col-md-12">
        <span (click)="goLocation('howitworks-for-shoppers')"
          style="background-color: white;border: 1px solid #3d85b0;color: #3d85b0;font-size: 14px;cursor: pointer;"
          class="btn btn-primary">Learn More</span>
      </div>
    </div>
    <div style="margin-top: 20px;" class="row">
      <div class="col-md-12">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe fetchpriority="low" width="100%" height="450" src="https://www.youtube.com/embed/s3JGFhPmuN8&rel=0"
            title="YouTube video player" frameborder="0" srcdoc="<style>
                  *{
                    padding: 0;
                    margin: 0;
                    overflow: hidden;
                  }
                   body{
                    height: 100%
                   } img{
                    position: absolute;
                    width: 100%;
                    left:0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                   } svg{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                   }
                </style>
                <a href='https://www.youtube.com/embed/s3JGFhPmuN8?autoplay=1&rel=0'>
                  <img fetchpriority='low' loading='lazy' src='https://img.youtube.com/vi/s3JGFhPmuN8/sddefault.jpg' alt='How it works shippn'>
                  <?xml version='1.0' encoding='UTF-8'?>
    <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='68px' height='48px' viewBox='0 0 68 48' version='1.1'>
    <defs>
    <filter id='alpha' filterUnits='objectBoundingBox' x='0%' y='0%' width='100%' height='100%'>
      <feColorMatrix type='matrix' in='SourceGraphic' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'/>
    </filter>
    <mask id='mask0'>
      <g filter='url(#alpha)'>
    <rect x='0' y='0' width='68' height='48' style='fill:rgb(0%,0%,0%);fill-opacity:0.121569;stroke:none;'/>
      </g>
    </mask>
    <clipPath id='clip1'>
      <rect x='0' y='0' width='68' height='48'/>
    </clipPath>
    <g id='surface5' clip-path='url(#clip1)'>
    <path style=' stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,0%,0%);fill-opacity:1;' d='M 27.027344 13.714844 L 43.097656 24.472656 L 45.355469 23.300781 Z M 27.027344 13.714844 '/>
    </g>
    <linearGradient id='linear0' gradientUnits='userSpaceOnUse' x1='512.5' y1='719.7' x2='512.5' y2='1.2' gradientTransform='matrix(0.0664062,0,0,-0.0665742,0,48)'>
    <stop offset='0' style='stop-color:rgb(89.803922%,17.647059%,15.294118%);stop-opacity:1;'/>
    <stop offset='1' style='stop-color:rgb(74.901961%,9.019608%,11.372549%);stop-opacity:1;'/>
    </linearGradient>
    </defs>
    <g id='surface1'>
    <path style=' stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;' d='M 27.027344 32.820312 L 45.355469 23.300781 L 27.027344 13.714844 Z M 27.027344 32.820312 '/>
    <use xlink:href='#surface5' mask='url(#mask0)'/>
    <path style=' stroke:none;fill-rule:nonzero;fill:url(#linear0);' d='M 67.269531 10.40625 C 67.269531 10.40625 66.605469 5.71875 64.574219 3.65625 C 61.996094 0.945312 59.101562 0.933594 57.78125 0.773438 C 48.285156 0.0859375 34.046875 0.0859375 34.046875 0.0859375 L 34.019531 0.0859375 C 34.019531 0.0859375 19.78125 0.0859375 10.285156 0.773438 C 8.964844 0.933594 6.070312 0.945312 3.492188 3.65625 C 1.460938 5.71875 0.796875 10.40625 0.796875 10.40625 C 0.796875 10.40625 0.121094 15.90625 0.121094 21.410156 L 0.121094 26.570312 C 0.121094 32.074219 0.796875 37.574219 0.796875 37.574219 C 0.796875 37.574219 1.460938 42.261719 3.492188 44.324219 C 6.078125 47.035156 9.460938 46.949219 10.96875 47.234375 C 16.394531 47.753906 34.03125 47.921875 34.03125 47.921875 C 34.03125 47.921875 48.285156 47.898438 57.78125 47.207031 C 59.109375 47.046875 61.996094 47.035156 64.574219 44.324219 C 66.605469 42.261719 67.269531 37.574219 67.269531 37.574219 C 67.269531 37.574219 67.945312 32.070312 67.945312 26.570312 L 67.945312 21.410156 C 67.945312 15.90625 67.269531 10.40625 67.269531 10.40625 Z M 27.027344 32.820312 L 27.027344 13.714844 L 45.355469 23.300781 Z M 27.027344 32.820312 '/>
    </g>
    </svg>
    
    
                </a>" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <!-- <div class="embed-responsive embed-responsive-16by9">
              <div class="youtube" data-embed="CkFkZv4_kcA">
                <div class="play-button"></div>
              </div>
            </div> -->
      </div>
    </div>
  </div>
  <div style="background-color: #222222;margin-top: 50px;" class="container-fluid">
    <div class="row">
      <div style="text-align: center;" class="col-md-12">
        <h4 class="header-underline-white"
          style="text-align: center;font-size: 26px;font-weight: bold;margin-bottom: 27px;color: white;margin-top: 27px;">
          START SHOPPING NOW</h4>
        <p style="color: white;margin-bottom: 27px;font-size: 14px;">Shippn lets you shop from any store in the world!
        </p>
      </div>
    </div>
  </div>
  <div style="background-color: #7c7c7c;" class="container-fluid">
    <div style="border-bottom: 1px solid #ffffff4d;" class="row">

      <div
        style="padding-left: 0;padding-right: 0;border: 1px solid #ffffff4d;display: inline-grid;align-items: center;min-height: 200px;"
        class="col-4 col-md-2">
        <div style="text-align: center;">
          <img fetchpriority="low" style="width: 40%;" loading="lazy" decoding="auto" src="../assets/img/brand-gap.webp"
            alt="GAP Logo">
        </div>
      </div>
      <div
        style="padding-left: 0;padding-right: 0;border: 1px solid #ffffff4d;display: inline-grid;align-items: center;min-height: 200px;"
        class="col-4 col-md-2">
        <div style="text-align: center;">
          <img fetchpriority="low" style="width: 40%;" loading="lazy" decoding="auto"
            src="../assets/img/brand-guess.webp" alt="Guess Logo">
        </div>
      </div>
      <div
        style="padding-left: 0;padding-right: 0;border: 1px solid #ffffff4d;display: inline-grid;align-items: center;min-height: 200px;"
        class="col-4 col-md-2">
        <div style="text-align: center;">
          <img fetchpriority="low" style="width: 55%;" loading="lazy" decoding="auto"
            src="../assets/img/brand-skechers.webp" alt="Skechers Logo">
        </div>
      </div>
      <div
        style="padding-left: 0;padding-right: 0;border: 1px solid #ffffff4d;display: inline-grid;align-items: center;min-height: 200px;"
        class="col-4 col-md-2">
        <div style="text-align: center;">
          <img fetchpriority="low" style="width: 55%;" loading="lazy" decoding="auto"
            src="../assets/img/brand-amazon.webp" alt="Amazon Logo">
        </div>
      </div>
      <div
        style="padding-left: 0;padding-right: 0;border: 1px solid #ffffff4d;display: inline-grid;align-items: center;min-height: 200px;"
        class="col-4 col-md-2">
        <div style="text-align: center;">
          <img fetchpriority="low" style="width: 40%;" loading="lazy" decoding="auto"
            src="../assets/img/brand-ebay.webp" alt="Jones New York Logo">
        </div>
      </div>
      <div
        style="padding-left: 0;padding-right: 0;border: 1px solid #ffffff4d;display: inline-grid;align-items: center;min-height: 200px;"
        class="col-4 col-md-2">
        <div style="text-align: center;">
          <img fetchpriority="low" style="width: 40%;" loading="lazy" decoding="auto"
            src="../assets/img/brand-timberland.webp" alt="Timberland Logo">
        </div>
      </div>



    </div>
  </div>
  <div
    style="background-image: url(../assets/img/country-background.webp); background-size: cover; background-repeat: no-repeat;">
    <div class="shippn-homepage-container container">
      <div class="row">
        <div style="text-align: center;" class="col-md-12">
          <h4 class="header-underline-white"
            style="text-align: center;font-size: 26px;font-weight: bold;margin-bottom: 27px;color: white;margin-top: 55px;">
            SHOP FROM ANYWHERE IN THE WORLD</h4>
          <p style="color: white;margin-bottom: 27px;font-size: 14px;">Let our host forward your package to your home
            address</p>


          <div style="margin-bottom: 60px;" class="carousel slide country-slide-padding" id="slider"
            data-ride="carousel">


            <div class="carousel-inner">

              <div class="carousel-item active">
                <div style="height: 100%;" class="row">
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('uk')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">UK</h2>
                    <a href="/{{lang}}/shop-from/uk" style="width:100%">
                      <img fetchpriority="low" loading="lazy" decoding="auto" width="100%" height="100%" srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-london-bg@576.webp 2x,
                            https://d1we2hgzmci25y.cloudfront.net/assets/img/region-london-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-london-bg.webp"
                        alt="United Kingdom London Westminster Bridge and Big Ben"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('us')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">U.S.A.</h2>
                    <a href="/{{lang}}/shop-from/us" style="width:100%">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-newyork-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-newyork-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-newyork-bg.webp"
                        alt="Shop from United States New York Night"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>
                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('ca')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">CANADA</h2>
                    <a href="/{{lang}}/shop-from/ca" style="width:100%">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-toronto-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-toronto-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-toronto-bg.webp"
                        alt="Shop from Canada Toronto"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>
                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('tr')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">TÜRKİYE</h2>
                    <a href="/{{lang}}/shop-from/tr" style="width:100%">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-istanbul-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-istanbul-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-istanbul-bg.webp"
                        alt="Shop from Turkey Istanbul "
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>
                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('au')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">AUSTRALIA</h2>
                    <a href="/{{lang}}/shop-from/au" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-australia-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-australia-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-australia-bg.webp"
                        alt="Shop from Austraila Sydney"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('de')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">GERMANY</h2>
                    <a href="/{{lang}}/shop-from/de" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-germany-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-germany-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-germany-bg.webp"
                        alt="Shop from Germany"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                </div>

              </div>


              <div class="carousel-item">
                <div class="row">
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('cn')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">CHINA</h2>
                    <a href="/{{lang}}/shop-from/cn" style="width:100%">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-china-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-china-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-china-bg.webp"
                        alt="Shop from China"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('fr')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">FRANCE</h2>
                    <a href="/{{lang}}/shop-from/fr" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-france-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-france-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-france-bg.webp"
                        alt="Shop form France, Paris Eiffel Tower"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('ae')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">UAE</h2>
                    <a href="/{{lang}}/shop-from/ae" style="width:100%">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-unitedarab-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-unitedarab-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-unitedarab-bg.webp"
                        alt="Shop from United Arab Emirates"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('in')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">INDIA</h2>
                    <a href="/{{lang}}/shop-from/in" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-india-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-india-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-india-bg.webp"
                        alt="Shop from India"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('it')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">ITALY</h2>
                    <a href="/{{lang}}/shop-from/it" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-italy-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-italy-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-italy-bg.webp"
                        alt="Shop from Italy Venice"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('jp')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">JAPAN</h2>
                    <a href="/{{lang}}/shop-from/jp" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-tokyo-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-tokyo-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-tokyo-bg.webp"
                        alt="Shop from Japan Tokyo Tokio"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                </div>

              </div>


              <div class="carousel-item">
                <div class="row">
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('nl')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">NETHERLANDS</h2>
                    <a href="/{{lang}}/shop-from/nl" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-holland-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-holland-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-holland-bg.webp"
                        alt="Shop from Netherlands Holland Amsterdam"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('nz')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">NEW ZEALAND</h2>
                    <a href="/{{lang}}/shop-from/nz" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-newzealand-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-newzealand-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-newzealand-bg.webp"
                        alt="Shop from New Zealand"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('ph')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">PHILIPPINES</h2>
                    <a href="/{{lang}}/shop-from/ph" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-philippines-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-philippines-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-philippines-bg.webp"
                        alt="Shop from PHILIPPINES"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('sg')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">SINGAPORE</h2>
                    <a href="/{{lang}}/shop-from/sg" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-singapore-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-singapore-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-singapore-bg.webp"
                        alt="Shop from Singapore"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('kr')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">SOUTH KOREA</h2>
                    <a href="/{{lang}}/shop-from/kr" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-southkorea-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-southkorea-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-southkorea-bg.webp"
                        alt="Shop from South Korea"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                  <div
                    style="display: flex;align-items: center;padding-top: 20px;text-align:center;justify-content:center;"
                    class="col-6 col-md-4">
                    <h2 (click)="goLocation('es')" class='top-countries-country-name'
                      style="color: white;position: absolute;margin:0;cursor: pointer;z-index: 500">SPAIN</h2>
                    <a href="/{{lang}}/shop-from/es" style="width: 100%;">
                      <img fetchpriority="low" loading="lazy" decoding="auto"
                        srcset="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-spain-bg@576.webp 2x, https://d1we2hgzmci25y.cloudfront.net/assets/img/region-spain-bg.webp 1x"
                        src="https://d1we2hgzmci25y.cloudfront.net/assets/img/region-spain-bg.webp"
                        alt="Shop from Spain"
                        style="width:100%;border-radius:5px;object-fit: cover;filter: brightness(50%)">
                    </a>

                  </div>
                </div>

              </div>


            </div>
            <a class="carousel-control-prev" href="#slider" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#slider" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>



        </div>
      </div>
    </div>
  </div>
  <div class="shippn-homepage-container container">


    <div class="row">
      <div class="col-md-12">
        <h4 class="header-underline"
          style="text-align: center;font-size: 26px;font-weight: bold;margin-bottom: 27px;margin-top: 35px;">WHAT MAKES
          SHIPPN SPECIAL?</h4>
      </div>
    </div>

    <div style="margin-top: 35px;" class="row">
      <div style="text-align: center;" class="col-sm-12 col-md-12 col-lg-4">
        <div class="shippn-homepage-what-makes-shippn-special">
          <img fetchpriority="low" style="width: 40px;margin-bottom: 15px;" loading="lazy" decoding="auto"
            src="../assets/img/homeicon.webp" alt="Home icon, half world and home.">
          <h4 style="text-align: center;font-size: 16px;font-weight: bold;margin-bottom: 12px;">Make The World Your Home
          </h4>
          <p style="font-size: 13px;line-height: 27px;">Want to buy a product from abroad, but the seller doesn't ship
            to your country? <strong>No worries, </strong> you can use our hosts' addresses to shop from any merchant in
            the world.
          </p>
        </div>

      </div>
      <div style="text-align: center;" class="col-sm-12 col-md-12 col-lg-4">
        <div class="shippn-homepage-what-makes-shippn-special">
          <img fetchpriority="low" style="width: 40px;margin-bottom: 15px;" loading="lazy" decoding="auto"
            src="../assets/img/worldicon.webp" alt="Rocket take off form earth icon">
          <h4 style="text-align: center;font-size: 16px;font-weight: bold;margin-bottom: 12px;">World-Class Handling
          </h4>
          <p style="font-size: 13px;line-height: 27px;">Let the world's best carriers handle your packages. Our
            logistics partners, <strong>DHL, TNT, UPS, </strong> and <strong>FedEx</strong> will deliver your packages
            within <br> <strong>1-5
              business days!</strong></p>
        </div>

      </div>
      <div style="text-align: center;" class="col-sm-12 col-md-12 col-lg-4">
        <div class="shippn-homepage-what-makes-shippn-special">
          <img fetchpriority="low" style="width: 40px;margin-bottom: 15px;" loading="lazy" decoding="auto"
            src="../assets/img/taxicon.webp" alt="Shipping Rates icon">
          <h4 style="text-align: center;font-size: 16px;font-weight: bold;margin-bottom: 12px;">Great Shipping Rates
          </h4>
          <p style="font-size: 13px;line-height: 27px;">Get the best service at an affordable price. We always get the
            best
            shipping rates from our logistics partners thanks to our rapidly growing volume.</p>
        </div>

      </div>

    </div>

  </div>
  <div style="background: black;margin-top: 80px;">
    <div style="padding-top:50px;padding-bottom: 50px;" class="shippn-homepage-container container">
      <div class="row">
        <div class="col-md-3 shippn-4-infos">
          <div>
            <img fetchpriority="low" loading="lazy" decoding="auto" src="../assets/img/team-host.webp"
              alt="hosts icon, three people icon" width="30px" height="30px">
            <div>
              <h4>Hosts</h4>
              <p style="font-size: 12px;;margin-bottom: 0;color: white;line-height: 19px;">Our hosts are ready to help
                you with shopping from more than {{hostCountriesCount}} countries.</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 shippn-4-infos">
          <div>
            <img fetchpriority="low" loading="lazy" decoding="auto" width="30px" height="30px"
              src="../assets/img/male-telemarketer.webp" alt="customer services icon, male telemarketer.">
            <div>
              <h4>Customer Service</h4>
              <p style="font-size: 12px;;margin-bottom: 0;color: white;line-height: 19px;">Our team works to give you
                the highest quality service based on your needs</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 shippn-4-infos">
          <div>
            <img fetchpriority="low" loading="lazy" decoding="auto" width="30px" height="30px"
              src="../assets/img/low-costs.webp" alt="low costs icon, dollar sign on hand icon.">
            <div>
              <h4>Low Costs</h4>
              <p style="font-size: 12px;;margin-bottom: 0;color: white;line-height: 19px;">We are working hard to offer
                you the best prices for the best service.</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 shippn-4-infos">
          <div>
            <img fetchpriority="low" loading="lazy" decoding="auto" width="30px" height="30px"
              src="../assets/img/fast-reliable.webp" alt="fast reliable icon, hand shake icon. ">
            <div>
              <h4>Fast & Reliable Delivery</h4>
              <p style="font-size: 12px;;margin-bottom: 0;color: white;line-height: 19px;">We work with world-renowned
                couriers to ship your products</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="top-rated-background">
    <img fetchpriority="low" src="./assets/img/mobile-top-rated.webp" loading="lazy"
      alt="Top rated hosts background mobile" class="top-rated-mobile-image" fetchpriority="low">
    <img fetchpriority="low" src="./assets/img/top-rated-background.webp" loading="lazy"
      alt="Top rated hosts background desktop" class="top-rated-desktop-image" fetchpriority="low">
    <div class="shippn-homepage-container container">
      <div class="row">
        <div style="text-align: center;" class="col-md-12">
          <h4 class="header-underline-white"
            style="text-align: center;font-size: 26px;font-weight: bold;margin-bottom: 27px;color: white;margin-top: 55px;">
            OUR TOP RATED HOSTS</h4>
          <div style="margin-bottom: 60px;" id="Carousel2" class="carousel slide country-slide-padding">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div *ngFor="let host of topRatedHostList | slice:0:4; trackBy:trackByIndex"
                    style="padding-top: 20px;" class="col-6 col-md-3">
                    <div style="max-width: 175px;margin-left: auto;margin-right: auto;">
                      <a href="/{{lang}}/shop-from/{{host.country_code}}" style="cursor: pointer;">
                        <img fetchpriority="low" class="black-white-img" loading="lazy" decoding="auto"
                          src="{{host.profile_image_url}}" alt="Shippn hosts profile picture."
                          style="width:100%;border-radius: 7px;">
                      </a>
                      <br>
                      <div class="row">
                        <div class="col-md-6 delete-account-left-to-center">
                          <h5 class="carousel-item-host-name" translate="no">
                            {{host.name}}
                          </h5>
                        </div>
                        <div class="col-md-6 bfm-explanation-right-to-center">
                          <div>
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                          </div>
                        </div>
                        <div class="col-md-12 delete-account-left-to-center">
                          <h5 style="color: white;font-size: 10px;margin-top: 7px;margin-bottom: 0px;width: 100%;">
                            {{host.city_name}}/{{host.country_name}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div *ngFor="let host of topRatedHostList | slice:4:8;trackBy:trackByIndex" style="padding-top: 20px;"
                    class="col-6 col-md-3">
                    <div style="max-width: 175px;margin-left: auto;margin-right: auto;">
                      <a href="/{{lang}}/shop-from/{{host.country_code}}" style="cursor: pointer;">
                        <img fetchpriority="low" class="black-white-img" loading="lazy" decoding="auto"
                          src="{{host.profile_image_url}}" alt="Shippn Host profile picture."
                          style="width:100%;border-radius: 7px;">
                      </a>
                      <br>
                      <div class="row">
                        <div class="col-md-6 delete-account-left-to-center">
                          <h5 class="carousel-item-host-name">{{host.name}}
                          </h5>
                        </div>
                        <div class="col-md-6 bfm-explanation-right-to-center">
                          <div>
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                          </div>
                        </div>
                        <div class="col-md-12 delete-account-left-to-center">
                          <h5 style="color: white;font-size: 10px;margin-top: 7px;margin-bottom: 0px;width: 100%;">
                            {{host.city_name}}/{{host.country_name}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div *ngFor="let host of topRatedHostList | slice:8:12;trackBy:trackByIndex"
                    style="padding-top: 20px;" class="col-6 col-md-3">
                    <div style="max-width: 175px;margin-left: auto;margin-right: auto;">
                      <a href="/{{lang}}/shop-from/{{host.country_code}}" style="cursor: pointer;">
                        <img fetchpriority="low" class="black-white-img" loading="lazy" decoding="auto"
                          src="{{host.profile_image_url}}" alt="Shippn Host profile picture"
                          style="width:100%;border-radius: 7px;">
                      </a>
                      <br>
                      <div class="row">
                        <div class="col-lg-6 delete-account-left-to-center">
                          <h5 class="carousel-item-host-name">{{host.name}}
                          </h5>
                        </div>
                        <div class="col-lg-6 bfm-explanation-right-to-center">
                          <div>
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                            <img fetchpriority="low" style="width: 10px;" loading="lazy" decoding="auto" width="10px"
                              height="10px" src="../assets/img/new-star.webp" alt="Orange star icon">
                          </div>
                        </div>
                        <div class="col-lg-12 delete-account-left-to-center">
                          <h5 style="color: white;font-size: 10px;margin-top: 7px;margin-bottom: 0px;width: 100%;">
                            {{host.city_name}}/{{host.country_name}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#Carousel2" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#Carousel2" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="background: black;">
    <div class="shippn-homepage-container container">
      <div class="row">
        <div style="margin-top: 50px;margin-bottom: 35px;" class="col-md-12">
          <div style="float: left;display: flex;align-items: center;">
            <i style="color: white;font-size: 24px;margin-right: 10px;" class="fab fa-instagram"></i>
            <span style="color: white;">/ shippncom</span>
          </div>
          <div style="float: right;">
            <a target="_blank" href="https://www.instagram.com/shippncom/">
              <h4 style="color: #ff7700;margin-top: 0;margin-bottom:0;font-size: 15px;text-decoration: underline;">
                Follow us</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
    <h4
      style="font-size: 26px;color: white;margin-top: 40px;padding-bottom: 30px;text-align: center;font-weight: bold;margin-bottom: 0;">
      OUR PARTNERS</h4>
  </div>
</div>
<!-- Edit Modal -->
<div class="modal fade" id="hostCountryRequestModal" tabindex="-1" aria-labelledby="Host Country Request Modal"
  aria-hidden="true">
  <div style="max-width: 800px;" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div style="background: #222222;" class="modal-header">
        <h5 style="color: white;padding-left: 15px;" class="modal-title" id="countryRequestModalTitle">Country Request
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span style="color: white;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <form ngNativeValidate [formGroup]="form">
        <div style="padding-left: 50px;padding-right: 50px;" class="modal-body">
          <div class="row">
            <div style="text-align: center;" class="col-md-12">
              <p style="color: black;text-align: center;font-size: 16px;font-weight: normal;margin-bottom: 5px;">
                Currently, we can only ship to the countries on the list, but Shippn is a growing community with the
                power of its hosts. If you can choose the country you want to shop from, you can be sure that we will do
                our best to give you the best service.</p>
            </div>
            <div style="margin-top: 20px;" class="col-12 col-lg-6">
              <label for="requestSelect"
                style="color: black;text-align: left;font-size: 16px;font-weight: bold;margin-bottom: 5px;">
                Please select a country</label>
              <select formControlName="country"
                style="margin-bottom: 0px; width: 100%; border-radius: 5px;padding: 11px 14px;background-color: transparent;font-size: 14px;height: 42px; z-index: 2;cursor: pointer;position: relative;"
                name="requestSelect" id="requestSelect">
                <option *ngFor="let countryData of requestNonHostList;trackBy:trackByIndex"
                  value="{{countryData.code}}">
                  {{countryData.name}}</option>
              </select>
            </div>
            <div style="margin-top: 20px;" class="col-12 col-lg-6">
              <p style="color: black;text-align: left;font-size: 16px;font-weight: bold;margin-bottom: 5px;">
                Your email address</p>
              <input type="email" [attr.disabled]="token" formControlName="email"
                [ngClass]="{'shippn-border-red':form.get('email').touched && form.get('email').invalid}"
                placeholder="Email Address" class="form-control form-control-sm shippn-input">
            </div>
          </div>
          <div class="row">
            <div style="margin-top: 10px;" class="col-md-12">
              <button (click)="saveHostRequest()" style="width: 100px;font-size: 16px;font-weight: normal;height: 38px;"
                class="btn shippn-from-to-area-go-btn"
                aria-labelledby="Submit Host Country Request Form">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<h1 style="position: absolute; top: -100vh; left: -100vw; z-index: -1; user-select: none;">
  SHOP ANYWHERE, SHIP EVERYWHERE!
</h1>