import { Injectable } from '@angular/core';
import {filter, from } from 'rxjs';
import { SignupService } from './signup.service';

@Injectable({ providedIn: 'root' })
export class NotificationsInfiniteScrollService {
    
    public notificationsCount: number = 10;

    constructor(private signupService: SignupService) {}

    fetchNotifications(currentLength: number, notificationsFromCenter) {
        let endLimit = currentLength + this.notificationsCount + 1;
        return from(notificationsFromCenter).pipe(
            filter((notification: any) => {
                let notifyIndex = notificationsFromCenter.findIndex( x => x.id == notification.id)
                return (
                    notifyIndex > currentLength && notifyIndex < endLimit
                );
            })
        );
    }
}