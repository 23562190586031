
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()

export class ExchangeRateService{



    fixerIOApi = 'https://data.fixer.io/api/latest?access_key=ff27fedee57beada0126a95f5ba10876&base=EUR';

    constructor(private http: HttpClient){

    }


    getExchangeRate(){
        return this.http.get(this.fixerIOApi);
    }


}