import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[appPreventEscOnInput]'
})
export class PreventEscOnInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.el.nativeElement === document.activeElement) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
