interface titleAndDescriptionModel {
    route: string,
    description: string,
    title: string

}

export const titlesAndDescriptions: titleAndDescriptionModel[] = [
    {
        route: "/shop-from-us-to-uk",
        description: "This page explains how to Shop online from the US through Shippn hosts that live in the US and get it delivered to your doorstep in UK.",
        title: "Shop from the US to UK  International Shipping with Shippn"
    },
    {
        route: "/shop-from-uk-to-au",
        description: "This page explains how to shop online from UK and ship through Shippn hosts that live in UK , get it delivered to your address in Australia.",
        title: "Shop from UK to Austrailia with Shippn - Fast and Easy Shipping"
    },
    {
        route: "/shop-from-us-to-il",
        description: "This page explains how to Shop online from the U.S.A. through Shippn hosts that live in U.S.A.. ",
        title: "Shop from US to Israil with Shippn - Fast and Reliable Shipping"
    },
    {
        route: "/shop-from-us-to-sg",
        description: "This page explains how to  Shop online from any  U.S.A.  online store through Shippn hosts that live in U.S.A..",
        title: "Shop from U.S.A. to Singapore - Easy and Reliable Shipping with Shippn"
    },
    {
        route: "/shop-from-us-to-de",
        description: "This page explains how to  Shop online from any  U.S.A.  online store through Shippn hosts that live in U.S.A..",
        title: "Shop from U.S.A. to Germany - Easy and Reliable Shipping with Shippn"
    },
    {
        route: "/shop-from-us-to-au",
        description: "This page explains how to  Shop online from the U.S.A. through Shippn hosts that live in U.S.A. and get your purchase delivered to your doorstep in Australia.",
        title: "Shop from U.S.A. to Australia - Easy and Reliable Shipping with Shippn"
    },
    {
        route: "/service-agreement",
        description: "This page indicates the the service agreement page for Shippn.",
        title: "Shippn Service Agreement - Understand Our Terms and Conditions"
    },
    {
        route: "/signin",
        description: "Welcome to our sign-in page! Sign in to access your account and start shopping, managing your profile, and more.",
        title: "Sign in to Shippn - Your Partner for Global Shipping Solutions"
    },
    {
        route: "/signup",
        description: "Ready to join our community? Sign up for an account today and enjoy exclusive access to our website's features.",
        title: "Sign Up for Shippn - Your Global Shipping Partner"
    },
    {
        route: "pricing",
        description: "Wondering about our pricing? Visit our pricing page to learn more about our packages and pricing plans.",
        title: "Shippn Pricing - Affordable and Transparent Global Shipping Rates"
    },
    {
        route: "shipping-cost-calculator",
        description: "Estimate your worldwide shipping costs with ease using Shippn's shipping cost calculator. Plan your shipments and stay informed every step of the way.",
        title: "Worldwide Shipping Cost Calculator - Shippn"
    },
    {
        route: "howitworks-for-shoppers",
        description: "Unlock the secrets of global shipping with Shippn. Learn how our innovative approach simplifies the journey from online checkout to doorstep delivery",
        title: "Global Commerce Insights: How It Works for Shoppers - Shippn"
    },
    {
        route: "getaddress-host",
        description: "This page explains how to get a host address from Shippn.com.",
        title: "Get address and Be a Member I Shippn "
    },
    {
        route: "shop-from/",
        description: "Discover a world of global products and enjoy fast shipping from anywhere with Shippn! Shop international brands easily and get your purchases delivered swiftly.",
        title: "Shop Global Products: Fast Shipping From Everywhere - Shippn"
    },
    {
        route: "bfm",
        description: "This page explains how Shippn's 'Buy For Me' service is the perfect solution for shoppers who want to shop globally.",
        title: "Buy For Me Service - Let Shippn Shop for You Worldwide"
    },
    {
        route: "bfm/explanation",
        description: "Shop globally hassle-free, thanks to Shippn's Buy For Me service.Simplify your international shopping and enjoy seamless parcel forwarding to your doorstep.",
        title: "Simplify Parcel Forwarding with Buy For Me Service - Shippn"
    },
    {
        route: "howitworks-for-hosts",
        description: "This page has all the information about how it works for hosts.",
        title: "Learn How Shippn Works for Hosts - Simplify Your Shipping Process"
    },
    {
        route: "terms",
        description: "This page covers important topics such as liability, intellectual property, and privacy, as well as the terms and conditions of using our various services. ",
        title: "Terms and Conditions: Read Our Policies & Guidelines - Shippn"
    },
    {
        route: "faq",
        description: "Find answers to your questions about worldwide shipping with Shippn's comprehensive FAQ section. Learn about our services, shipping policies, and more",
        title: "FAQ's About Worldwide Shipping - Shippn"
    },
    {
        route: "trust-safety",
        description: "Experience peace of mind with Shippn's reliable worldwide shipping service. Trust us to deliver your packages safely and swiftly to destinations across the globe",
        title: "Reliable Worldwide Shipping - Shippn"
    },
    {
        route: "privacy-policy",
        description: " Shippn's 'Privacy Policy' page outlines the ways we collect, use, and protect your personal information.",
        title: "Shippn Privacy Policy - Your Data is Safe with Us"
    },
    {
        route: "/become-a-host",
        description: "Join Shippn's host network and play a vital role in global shopping assistance. Assist shoppers worldwide, and be a part of a thriving global community.",
        title: "Global Shopping Assistance: Become a Host - Shippn"
    },
    {
        route: "/help",
        description: "At Shippn, we're here to help you shop and ship internationally with ease. ",
        title: "Shippn Help Center - Find Answers to Your Global Shipping Questions"
    },
    {
        route: "/responsible-shipping",
        description: "At Shippn, our Responsible Shipping page outlines our policies and procedures for protecting your shipments.",
        title: "Responsible Shipping with Shippn - Our Commitment to Sustainability"
    },
    {
        route: "/shipping-standards",
        description: "Discover Shippn's commitment to worldwide shipping standards. We adhere to the highest quality and reliability for your peace of mind.",
        title: "Worldwide Shipping Standards - Shippn"
    },
    {
        route: "/prohibited-items",
        description: "Explore Shippn's list of prohibited items for international shipping. We prioritize safety and legality to ensure smooth customs clearance and delivery",
        title: "Prohibited Items for International Shipping - Shippn"
    },
    {
        route: "/contact-us",
        description: "Reach out to Shippn for comprehensive global shipping solutions. Contact us today to explore how we can help streamline your international shipping needs.",
        title: "Contact Us for Global Shipping Solutions - Shippn"
    },
    {
        route: "/about",
        description: "Learn more about Shippn and our commitment to fast shipping. Discover how we're revolutionizing global logistics to ensure your parcels reach you swiftly",
        title: "About Us & Our Fast Shipping Service - Shippn"
    },
    {
        route: "/shipper-network",
        description: "Become part of Shippn's ShipNet and connect with millions of shoppers worldwide. Provide top-notch shipping services and grow your business globally.",
        title: "Shipper Network: Domestic & International Shipping - Shippn"
    },
    {
        route: "/HELLO10",
        description: "Enjoy 10% off your first shipment with Shippn using promo code HELLO10. ",
        title: "Use code HELLO10 for %10 discount"
    },
    {
        route: "/hello10",
        description: "Enjoy 10% off your first shipment with Shippn using promo code HELLO10. ",
        title: "Use code HELLO10 for %10 discount"
    },
    {
        route: "/home",
        description: "Discover hassle-free global shipping and international shopping with Shippn. Shop from anywhere in the world and let us handle the delivery logistics for you",
        title: "International Shipping Service: Global Shop Options - Shippn"
    },
    {
        route: "/b2b",
        description: "Simplify your international shipping process with Shippn. Ship your products globally, connect your business with your customers worldwide.",
        title: "Worldwide Shipping Service | Shop Anywhere, Ship Everywhere - Shippn"
    },
    {
        route: "/shopping",
        description: "Discover seamless global shopping experiences with Shippn. Access international markets effortlessly and enjoy convenient shipping solutions.",
        title: "Global Shopping - Shippn"
    },
    {
        title: "How to Shop Shein US & Ship Internationally - Shippn",
        route: "/how-to-shop-shein-us-and-ship-internationally",
        description: "Discover the easiest way to buy from Shein US and ship globally with Shippn. Follow our step-by-step guide to worldwide fashion."
    },
    {
        title: "How to Shop Walmart & Ship Internationally - Shippn",
        route: "/how-to-shop-walmart-and-ship-internationally",
        description: "Shop Walmart’s vast selection and ship internationally with Shippn’s expert tips. Explore the best methods for reliable global delivery of your purchases."
    },
    {
        title: "How to Shop Amazon USA & Ship Internationally - Shippn",
        route: "/how-to-shop-amazon-and-ship-internationally",
        description: "Discover how to shop from Amazon and ship internationally with Shippn. Learn the best tips for easy and efficient global delivery."
    },
    {
        title: "How to Shop Macy’s & Ship Internationally - Shippn",
        route: "/how-to-shop-macys-and-ship-internationally",
        description: "Shop Macy's with ease and ship internationally using Shippn's services. Follow our step-by-step guide for a smooth global shopping experience."
    },
    {
        title: "How to Shop RockAuto & Ship Internationally - Shippn",
        route: "/how-to-shop-rockauto-and-ship-internationally",
        description: "Shop RockAuto from anywhere and ship internationally with Shippn's guide. Enjoy hassle-free global delivery of all your auto parts needs."
    },
    {
        title: "How to Shop Bath & Body Works & Ship Internationally - Shippn",
        route: "/how-to-shop-bath-and-body-works-and-ship-internationally",
        description: "Shop Bath & Body Works from anywhere with Shippn's guide. Learn how to get your favorite products shipped internationally hassle-free."
    },

    {
        title: "How to Shop Carter’s US & Ship Internationally - Shippn",
        route: "/how-to-shop-carters-and-ship-internationally",
        description: "Learn how to shop Carter’s US and ship internationally with Shippn. Get your favorite kids' clothes delivered anywhere in the world easily."
    },
    {
        title: "How to Shop Dick's Sporting Goods & Ship Globally - Shippn",
        route: "/how-to-shop-dicks-sporting-goods-and-ship-internationally",
        description: "Learn how to shop Dick's Sporting Goods and ship internationally with Shippn. Enjoy top sports gear, apparel, and global delivery."
    },
    {
        title: "How to American Eagle & Ship Internationally - Shippn",
        route: "/how-to-shop-american-eagle-and-ship-internationally",
        description: "Learn how to shop American Eagle and ship internationally with Shippn. Get your favorite AE styles delivered worldwide, hassle-free."
    },
    {
        title: "How to Shop Part Geeks & Ship Internationally - Shippn",
        route: "/how-to-shop-part-geeks-and-ship-internationally",
        description: "Discover how to shop Parts Geek and ship internationally with Shippn. Access a wide range of car parts and enjoy reliable global shipping."
    },
    {
        title: "How to Shop CJ Pony Parts & Ship Internationally - Shippn",
        route: "/how-to-shop-cj-pony-parts-and-ship-internationally",
        description: "Find out how to shop CJ Pony Parts and ship globally with Shippn. Enjoy reliable shipping and access to top-notch car accessories."
    }


]