import { Injectable } from "@angular/core";
import {BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class BarcodeScannerService {
    private barcodeScannerStatusSource = new BehaviorSubject<boolean>(false);
    onbarcodeScannerStatusSource = this.barcodeScannerStatusSource.asObservable();
    constructor() {}

    changeBarcodeStatus(status: boolean){
        this.barcodeScannerStatusSource.next(status);
    }
}