import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[LazyLoad]'
})
export class LazyLoadDirective implements AfterViewInit {
  @Input('LazyLoad') lazyImage!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', `url(${this.lazyImage})`);
          intersectionObserver.unobserve(this.el.nativeElement);
        }
      });
    });

    intersectionObserver.observe(this.el.nativeElement);
  }
}
