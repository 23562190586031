import {  ActionReducer, INIT, UPDATE, createReducer, on } from "@ngrx/store";
import * as AuthActions from './auth.actions';

export interface AuthState{
    token: string | null;
    user: any | null;
    isAuthenticated: boolean;
}

const authInitialState: AuthState = {
    token: null,
    user: null,
    isAuthenticated: false
}

export const authReducer = createReducer(
    authInitialState,
    on(AuthActions.LoginSuccess, (state, action) => {
        return {
            ...state,
            token: action.token,
            user: action.user,
            isAuthenticated: true
        }
    }),
    on(AuthActions.Logout, (state, action) => {
        return {
            ...state,
            token: null,
            user: null,
            isAuthenticated: false
        }
    }),
    on(AuthActions.SetUser, (state, action)=>{ 
        return{
            ...state,
            user: action.user
        }
    })
)

export const metaReudcerLocalStorage = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {

        if (action.type === INIT || action.type == UPDATE) {
            const storageValue = localStorage.getItem('state');
            if (storageValue) {
                try {
                    return JSON.parse(storageValue)
                } catch {
                    localStorage.removeItem("state")
                }
            } else {
                localStorage.removeItem("state")
            }

        }
        const nextState = reducer(state, action);
        localStorage.setItem("state", JSON.stringify(nextState))
        return nextState;
    }
}