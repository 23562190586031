import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WatcherService } from 'src/app/services/watcher.service';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  constructor(
    private watcherService: WatcherService,
    private router: Router
  ) { }
  lang = 'en';
  ngOnInit(): void {
    this.watcherService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    )
    setTimeout(() => {
      this.router.navigate(['/', this.lang, 'home'])
    }, 2500);
  }
}
