import { createAction, props } from "@ngrx/store";
import { packageOrder } from "./package-order.model";

export enum packageOrderActionTypes {
    createPackageOrderList = '[Package Order] Create Package Order List',
    deletePackageOrderList = '[Package Order] Delete Package Order List',
    updatePackageOrderList = '[Package Order] Update Package Order List',
    getPackageOrderList = '[Package Order] Get Package Order List',
    addPackageOrderToList = '[Package Order] Add Package Order To List',
    removePackageOrderFromList = '[Package Order] Remove Package Order From List',
    updatePackageOrderInList = '[Package Order] Update Package Order In List',
    
}

export const CreatePackageOrderList = createAction(
    packageOrderActionTypes.createPackageOrderList,
    props<{ packageOrdersList: packageOrder[] }>()
)

export const DeletePackageOrderList = createAction(
    packageOrderActionTypes.deletePackageOrderList
)

export const UpdatePackageOrderList = createAction(
    packageOrderActionTypes.updatePackageOrderList,
    props<{ packageOrdersList: packageOrder[] }>()
)

export const GetPackageOrderList = createAction(
    packageOrderActionTypes.getPackageOrderList
)

export const AddPackageOrderToList = createAction(
    packageOrderActionTypes.addPackageOrderToList,
    props<{ packageOrder: packageOrder }>()
)
export const RemovePackageOrderFromList = createAction(
    packageOrderActionTypes.removePackageOrderFromList,
    props<{ packageOrder: packageOrder }>()
)

export const UpdatePackageOrderInList = createAction(
    packageOrderActionTypes.updatePackageOrderInList,
    props<{ packageOrder: packageOrder }>()
)