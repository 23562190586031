export const topGlobalStores = [
    {
        name: 'Amazon',
        image: 'assets/img/stores/amazon/Amazon_logo.png',
        urlPart: 'how-to-shop-amazon-and-ship-internationally',
    },
    {
        name: 'Shein',
        image: 'assets/img/stores/shein/How_to_Ship_Shein_Clothing_Internationally.avif',
        urlPart: 'how-to-shop-shein-us-and-ship-internationally',
    },
    {
        name: 'Walmart',
        image: 'assets/img/stores/walmart/walmart_logo.webp',
        urlPart: 'how-to-shop-walmart-and-ship-internationally',
    },
    {
        name: `Macy's`,
        image: 'assets/img/stores/macys/macys_logo.png',
        urlPart: 'how-to-shop-macys-and-ship-internationally',
    },
    {
        name: 'RockAuto',
        image: 'assets/img/stores/rockauto/rockauto_logo.webp',
        urlPart: 'how-to-shop-rockauto-and-ship-internationally',
    }
]