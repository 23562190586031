let ga4IdVal;
if (document.location.hostname === 'shippn.com' || document.location.hostname === 'www.shippn.com') {
  ga4IdVal = 'G-BEDQ89S6B0';
} else if (document.location.hostname === 'preprodv2.shippn.com') {
  ga4IdVal = 'preprodv2';
} else if (document.location.hostname === 'perf.shippn.com') {
  ga4IdVal = 'perf';
} else {
  ga4IdVal = 'testingAccount';
}

export const environment = {
  production: false,
  name: 'staging',
  googleRecaptchaSiteKey: "6LfzUlUaAAAAAKKv0UBaepUWBOJKiZ62xqtvqREh",
  googleSecretKey: "",
  firebase: {
    projectId: 'shippn-f5518',
    appId: '1:1056990159945:web:8e63795eaa00e1b96a0b98',
    databaseURL: 'https://shippn-f5518.firebaseio.com',
    storageBucket: 'shippn-f5518.appspot.com',
    apiKey: 'AIzaSyBgm0AbeCNUr-VgD93TcZTVZ_3Z-VZB5nI',
    authDomain: 'shippn-f5518.firebaseapp.com',
    messagingSenderId: '1056990159945',
  },
  ga4Id: ga4IdVal,
  sentry: {
    dsn: 'https://3bb446c883bab5956405d0c558f56292@o4504530895765504.ingest.us.sentry.io/4507900314386432',
    tracesSampleRate: 1.0,
    environment: 'staging'
  }
};