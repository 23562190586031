import { createAction, props } from "@ngrx/store";

export enum authActionTypes{
    login = '[Auth] Login',
    loginSuccess = '[Auth] Login Success',
    loginFailure = '[Auth] Login Failure',
    logout = '[Auth] Logout',
    logoutSuccess = '[Auth] Logout Success',
    setUser = '[Auth] Set User'
}

export const Login =  createAction(
    authActionTypes.login, 
    props<{email: string, password: string}>()
)
export const LoginSuccess = createAction(
    authActionTypes.loginSuccess,
    props<{token: string, user: object }>()
)
export const LoginFailure = createAction(
    authActionTypes.loginFailure
)
export const Logout = createAction(
    authActionTypes.logout
)
export const LogoutSuccess = createAction(
    authActionTypes.logoutSuccess
)

export const SetUser = createAction(
    authActionTypes.setUser,
    props<{user: object}>()
)