import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstantCalculatorDisocuntCountdownService {
  private countdownMinutes: number = 10;
  private countdownSeconds: number = 0;
  private countdownInterval: any;
  private remainingTimeSubject = new BehaviorSubject<{ minutes: number, seconds: number }>({ minutes: this.countdownMinutes, seconds: this.countdownSeconds });
  private timerStartSubject = new BehaviorSubject<boolean>(false);
  private timerFinishedSubject = new BehaviorSubject<boolean>(false);
  private countdownRunning: boolean = false;
  private localStorageKey = 'countdown_timer';

  constructor() {
    this.loadCountdownState();
    if (this.getTimerStatus().started) {
      this.startCountdown();
    }
  }

  public startCountdown() {
    if (!this.countdownRunning) {
      this.countdownRunning = true;
      this.timerStartSubject.next(true);
      this.countdownInterval = setInterval(() => {
        if (this.countdownSeconds === 0) {
          if (this.countdownMinutes === 0) {
            clearInterval(this.countdownInterval);
            this.timerFinishedSubject.next(true);
            localStorage.removeItem(this.localStorageKey);
            this.countdownRunning = false;
          } else {
            this.countdownMinutes--;
            this.countdownSeconds = 59;
          }
        } else {
          this.countdownSeconds--;
        }
        this.remainingTimeSubject.next({ minutes: this.countdownMinutes, seconds: this.countdownSeconds });
        this.saveCountdownState();
      }, 1000);
    }
  }

  public stopCountdown() {
    if (this.countdownRunning) {
      clearInterval(this.countdownInterval);
      this.countdownRunning = false;
      this.timerStartSubject.next(true);
      this.timerFinishedSubject.next(true);
    }
    let state = { 
      minutes: 0, 
      seconds: 0, 
      started: true, 
      finished: true,
      shown: 1
    };
    
    localStorage.setItem(this.localStorageKey, JSON.stringify(state));
  }

  private loadCountdownState() {
    const stateString = localStorage.getItem(this.localStorageKey);
    if (stateString) {
      const state = JSON.parse(stateString);
      this.countdownMinutes = state.minutes;
      this.countdownSeconds = state.seconds;
      if (state.started) {
        this.timerStartSubject.next(true);
      }
      if (state.finished) {
        this.timerFinishedSubject.next(true);
      }
    }
  }
  private saveCountdownState() {
    let shownValue = this.timerStartSubject.value && this.timerFinishedSubject.value && this.countdownMinutes === 0 && this.countdownSeconds === 0 ? 1 : 0;
    let state = { 
      minutes: this.countdownMinutes, 
      seconds: this.countdownSeconds, 
      started: this.timerStartSubject.value, 
      finished: this.timerFinishedSubject.value,
      shown: shownValue
    };
    
    localStorage.setItem(this.localStorageKey, JSON.stringify(state));
  }

  getRemainingTime(): Observable<{ minutes: number, seconds: number }> {
    return this.remainingTimeSubject.asObservable();
  }

  getTimerStartSignal(): Observable<boolean> {
    return this.timerStartSubject.asObservable();
  }

  getTimerFinishedSignal(): Observable<boolean> {
    return this.timerFinishedSubject.asObservable();
  }

  getTimerStatus(): { started: boolean, finished: boolean, shown: number } {
    return { 
      started: this.timerStartSubject.value, 
      finished: this.timerFinishedSubject.value,
      shown: this.timerStartSubject.value && this.timerFinishedSubject.value && this.countdownMinutes === 0 && this.countdownSeconds === 0 ? 1 : 0
    };
  }
}
