<div id="shippn-scroll" class="col-md-12 content-text {{textColorClass}} maxHeight-{{maxHeight}}">
    <h4>OUR SERVICES</h4>
    <p>
        1.1 SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ.. services allow you to send parcel/s with a choice of the major courier companies that we hold
        accounts with. In these terms of use, we refer to these courier companies as the “Carrier”.
    </p>

    <p>
        1.2 The carriage of items is undertaken by the Carrier and we do not carry the items ourselves. However, your
        contract for the carriage of items remains between you and Shippn, we manage all aspects of the interaction with
        the Carrier on your behalf (including, for example, the placing of the order itself and any service complaints
        you may have). Accordingly, if you have any queries or issues about any order you place, you should contact
        Shippn as described in section 13.1 (below)
    </p>

    <p>
        1.3 When you place an order with us, you can select from a range of different Carriers depending upon the
        services you require. We will provide our services to you using reasonable skill and care and have selected only
        reputable Carriers to carry Consignments.
    </p>

    <p>
        1.4 Please note that our website allows for the automated placing of orders without any human intervention by
        Shippn. In other words, we do not 'check' your order before it is placed with the Carrier companies. It is
        therefore essential that the order is correctly entered and declared in order for the correct Services and
        pricing to be displayed. Refer also to Surcharges 6.3
    </p>

    <p>
        1.5 Our sign up process contains a checkbox that must be ticked to state that you have understood these Terms of
        use. Before signing, you should read these Terms (and all related information which is linked to as part of the
        order process, including, for example, the relevant FAQ's section and the list of Prohibited / Restricted Items)
        to ensure that you understand the terms on which we provide our services.
    <p>
        1.6 “Prohibited Items” (Including, but not limited to - hazardous items, pornographic items and tobacco
        products) must not be sent using our service.
    </p>

    <p>
        1.7 “Restricted Items” (Including, but not limited to - glass items,mirrored goods) must not be sent using our
        service.
    </p>

    <p>
        1.8 If, notwithstanding sections 1.6 and 1.7, you do decide to use our service to send Prohibited or Restricted
        Items, you should be aware that Shippn does not offer the same level of contractual protection for loss or
        damage to Prohibited and Restricted Items as for non-Prohibited/Restricted items. Further details are set out
        below in sections 4.7, 4.8,10 and 11.
    </p>

    <h4>DEFINITIONS</h4>

    <p>
        2.1 Listed below are defined terms which will have the following meanings in these terms of use:
    </p>

    <p>
        2.1.1 AWB / Airwaybill / Airway bill – The documentation placed on the parcel.
    </p>

    <p>
        2.1.2 Carrier - means the third party courier company which carries the Consignment.
    </p>

    <p>
        2.1.3 Consignment – Each parcel or group of parcels sent using Shippn's service to each individual address
    </p>

    <p>
        2.1.4 Insurance – means an additional level of Transit Cover that replaces the included cover which is purchased
        at the time that an order is placed as further described in paragraph 4 (below).
    </p>

    <p>
        2.1.5 Export Services - means a service where the Consignment is collected in the origin country and delivered
        to an address outside of the origin country.
    </p>

    <p>
        2.1.6 Discarded - When a Consignment is 'abandoned'/destroyed.
    </p>

    <p>
        2.1.7 Packaging - the outermost materials used to wrap or protect your goods
    </p>

    <p>
        2.1.8 Prohibited Item – means an item which must not be sent using Shippn's services, for example because the
        item is dangerous or Hazardous.
    </p>

    <p>
        2.1.9 Receiver – The person who is receiving the Consignment.
    </p>

    <p>
        2.1.10 Restricted Item – means an item which it is strongly recommended that you do not send using Shippn's
        services, for example because the item is fragile, as is further described here Prohibited / Restricted Items
    </p>

    <p>
        2.1.11 Working Day – means in relation to the origin country, Monday to Friday from 8.30am to 6pm excluding
        public holidays and in relation to a destination country, the days at times that banks are normally open for
        business in that country excluding public holidays.
    </p>

    <h4>PLACING AN ORDER AND YOUR RIGHT TO CANCEL</h4>

    <p>
        3.1 Shippn is not obliged to accept orders from you – and a contract for the services will only be formed when
        we accept your order and confirm this to you. Shippn reserves the right to refuse and cancel any order and
        operate sophisticated Payment & Fraud security checks.
    </p>

    <p>
        3.2 Shippn can only accept orders online from the account owner, who must be the end user and not another third
        party broker. A valid card or PayPal Account must be used to purchase in order to ensure VAT is applied
        correctly and any refunds / credits due can be applied.
    </p>

    <p>
        3.3 Shippn will arrange delivery of the Consignment/s through a third party service with a reputable Carrier as
        chosen by you at the time of ordering. Should that service not be available once purchased you will be
        immediately contacted with a comparable alternative or right to cancel.
    </p>

    <p>
        3.4 The collection of a Consignment will normally occur on your chosen date which can be booked up to 7 days
        ahead. Any order can be canceled by you and a full refund will be given up until the time that the Consignment
        is collected from you. HOWEVER, AFTER A CONSIGNMENT HAS BEEN COLLECTED FROM YOU, YOUR ORDER CANNOT BE CANCELED.
        This is because we have placed the order on your behalf with the Carrier and will be charged for the collection.
        By arranging for the Consignment to be collected, you consent to the provision of services to you and
        accordingly you will not be able to exercise any legal cancellation right that you may have (also known as a
        'cooling off' right) from the point when the Consignment is collected from you.
    </p>

    <p>
        3.5 The order and any cancellation of order will be confirmed in writing. If this is not received, or if you
        require duplicate documentation, please contact our offices as described in section 13 and the transaction will
        be confirmed.
    </p>

    <p>
        3.6 An order can only be put on hold and re activated up to a period of seven days after ordering. After that
        point a new order should be placed.
    </p>

    <h4>INSURANCE</h4>
    <p>
        4.1 Insurance can be purchased during order placement.
    </p>

    <p>
        4.2 A claim can only be started & settled on production of an invoice or receipt proving the value of the goods.
    </p>

    <p>
        4.3 The carrier may arrange inspection as part of the claims process.
    </p>

    <p>
        4.4 A claim can only be made up to the covered maximum and the claim can only include the cost of the item plus
        postage paid through Shippn. Please note therefore we do not cover listing fees, packaging or any additional
        charge made to a third party for postage as part of the claim.
    </p>

    <p>
        4.5 You can buy additional Insurance for an additional fee which is payable at the time of ordering.
    </p>

    <p>
        4.6 Insurance can be purchased for the value of your goods up to the maximum value allowed for each individual
        service. In such circumstances (where maximum Insurance has been selected), the maximum value which Shippn will
        pay for loss and damage on production of proof of value will be the covered value outlined at the time of
        booking and confirmed once the order is placed. A claim will only be entertained up to the covered maximum.
    </p>

    <p>
        4.7 Insurance is not valid for Prohibited Items and will only cover Loss on Restricted Items. Given that our
        website accepts orders on an automated basis, you may be able to purchase Insurance when you place your order
        for the carriage of Prohibited Items or Restricted Items (depending on the information which is submitted when
        you place your order). If this is the case, you are entitled to a refund of the amount that you paid for the
        Insurance on a Prohibited item as the insurance is not valid. Should a refund request be made for insurance on a
        Restricted item, then the refund will only be possible prior to the goods being collected. Once in transit the
        insurance is valid for Loss only as stated in these Terms.
    </p>

    <p>
        4.8 For further details on the carriage of Restricted Items and Prohibited Items, please see sections 10 and 11
        (below). For further information on the level of claims you can make against Shippn and Shippn's limitation on
        liability, please see section 15.
    </p>

    <h4>CHARGES</h4>

    <p>
        5.1 All prices quoted on this website are in US dollars.
    </p>

    <p>
        5.2 Payment can be made by:

    </p>

    <p>
        5.2.1 At the time of ordering using a valid Credit / debit card.
    </p>

    <p>
        5.2.2 By using a card that has been previously stored securely by our payment processor through our site for
        repeat transactions.
    </p>

    <p>
        5.2.3 By PayPal.
    </p>

    <p>
        5.3 Payment is taken by our automated system at the end of your order once the service has been booked with our
        Carrier. PLEASE NOTE THAT IN ADDITION TO THE CHARGES WHICH ARE QUOTED AT THE TIME OF YOUR ORDER, SURCHARGES MAY
        ALSO BE PAYABLE BY YOU IF, FOR EXAMPLE, THE CONSIGNMENT IS NOT AS DESCRIBED WHEN COLLECTION IS ATTEMPTED OR IF
        THE CONSIGNMENT IS NOT AVAILABLE FOR COLLECTION AT THE SPECIFIED TIME. FURTHER DETAILS ARE SET OUT IN SECTION 6
        BELOW
    </p>

    <p>
        5.4 Regular users will be given the option to upgrade to different tiers resulting in discounted rates.
    </p>

    <p>
        5.5 Any Promotional code that has been issued must be entered at the time of ordering and will NOT be refunded
        after the order is completed if it has not been entered.

    </p>

    <h4>SURCHARGES</h4>
    <p>
        6.1 Certain surcharges may be payable by you in addition to the carriage fees which are set out as the cost for
        the standard delivery of your order. When a surcharge is payable, it will be charged directly to the payment
        method used to make the initial order (if you have consented to this). If we have not obtained your consent to
        charge surcharges directly to your original payment method, we will contact you directly to arrange payment.

    </p>

    <p>
        6.2 Any Surcharges represent the additional administrative costs which will be suffered by Shippn and charges
        which Shippn may incur from the Carriers and are not penalties imposed by Shippn. This information is made
        available to you prior to placing your order.
    </p>

    <p>
        6.3 For illustrative purposes, the following is a non-exhaustive list of when surcharges may be payable.
    </p>

    <p>
        6.3.1 A minimum surcharge of $5.00 will be applied if you are out when the driver tries to collect or if the
        Consignment is otherwise unavailable for collection.
    </p>

    <p>
        6.3.2 Other surcharges may be applicable if the receiver refuses to take delivery of the goods and they need to
        be sent back to you. These include but are not limited to return transit cost.
    </p>

    <p>
        6.3.3 Some areas will be subject to a remote area surcharge. This will be calculated in the quoting system prior
        to the point of booking. Should a change of delivery request be made once in transit, a Surcharge may be applied
        depending on the new locality.
    </p>

    <p>
        6.3.4 You are pre paying for the postage charges. Any Customs charges for overseas shipments should be payable
        by the receiver or may be passed on to the Sender should they arise. Please see the Customs clearance section 12
        below which also covers return charges should the customs charges not be paid.
    </p>
    <p>
        6.3.5 Shippn cannot carry pallets for orders placed online. Please contact us if such service is needed.
    </p>
    <p>
        6.3.6 We may supply Documentation to accompany your shipment. Failure to attach this could result in a
        Surcharge. See section 6 and 7.7.
    </p>

    <h4> COLLECTIONS </h4>
    <p>
        7.1 Collection dates and times are not guaranteed. Further details are in section 9 below. However, please note
        your right to cancel an order as described in paragraph 3.4 above.
    </p>
    <p>
        7.2 The automated system books the collection as per the customer's request. The system will tell you if the
        time slot is available and if not you can select an alternative. Please note, we cannot specify a morning
        collection. Any time slots selected are only a request and collections may be made any time from 8.30am up until
        6pm.
    </p>
    <p>
        7.3 Collections will be made from the main front door, reception or goods in. The drivers will not collect from
        an individual department, block or flat number.
    </p>
    <p>
        7.4 Our automated ordering system books the collection as requested by the customer. In the rare event that the
        Carrier cannot make the collection please contact Shippn immediately where we will re-book for collection the
        same day if cut off has not passed or the next working day. Please be aware that we are not aware of any issues
        with collection until we are contacted and the carrier should not be contacted directly as the booking is made
        on the Shippn account with the carrier.
    </p>
    <p>
        7.5 Shippn does not come into direct contact with the Consignment/s but arranges for the collection through one
        of the major Carriers that we hold an account. Please ensure the correct parcel is given to the correct
        collecting agent that you have chosen at the time of ordering.
    </p>
    <p>
        7.6 Export services can be collected from a residential address or business. Additional charges will apply for
        collections from residential addresses. If the delivery address is residential, residential delivery surcharge
        will apply.
    </p>
    <p>
        7.7 We may supply documentation to accompany your shipment. You will be advised of this at the time of ordering.
        This must be attached to the shipment, if not your shipment could be delayed and be subject to an additional
        premium (see section 6 regarding surcharges). Further instructions will be found in the confirmation email sent
        after the booking is placed.
    </p>
    <p>
        7.8 Your Consignment/s must be packed to a reasonable standard, packed within a double walled cardboard box with
        the contents cushioned and protected inside. The packaging must also be sufficient to protect the Consignment's
        weight. It is not always obvious when a Consignment has not been packaged properly. The Carriers will assume
        that Consignments have been correctly packaged and will exercise a level of skill and care appropriate to that.
        Any claim resulting from a parcel that is not packaged to a reasonable standard and in line with the above may
        be declined. For further details see section 14 below.
    </p>
    <p>
        7.9 Please note that any item traveling through our services must be able to withstand a short drop, fragile
        items should not be sent through our services. Please see our packaging guidelines and Prohibited / Restricted
        Items in addition and also sections 10 and 11 relating to Prohibited Items and Restricted Items generally.
    </p>
    <p>
        7.10 Prohibited Items and Restricted Items and Consignments which have not been packaged properly should not be
        sent using our services. If they are sent using our services, the affected item could be subject to delay,
        return, impounded by Customs or held for collection by you or the receiver. If the goods are held to be
        collected, you will be notified that collection of said goods must be arranged by a certain date or the goods
        may incur storage charges and finally be discarded. To clarify, the goods may be discarded if i) Customs remove
        and destroy the Prohibited item (This only applies to Prohibited items not Restricted) ii) they are damaged to
        such an extent that it is a Health and Safety risk (such as smashed glass); iii) if they have been held for
        collection for a fixed time limit and the time limit advised has been exceeded.
    </p>
    <p>
        7.11 Parcels should not be strapped or attached together. This is not a secure way for parcels to travel in the
        Carrier network. Any item which is not securely packaged or strapped to another package will be treated as a
        Prohibited Item (see section 11 for further information)
    </p>
    <p>
        7.12 The Carrier / Shippn have the right to refuse a Consignment for a reasonable reason such as no packaging,
        insufficient packaging or the Consignment does not comply with the information given by you at the time of
        placing the order - for example is not labeled correctly, contains a Prohibited item or is larger than stated.
    </p>
    <p>
        7.13 Collections are made on Working days only.
    </p>

    <p>
        7.14 Please ensure the collection point is available at the collection time that you request. A surcharge of
        $5.00 may be applied if you are out or the Consignment is otherwise unavailable when the Carrier tries to
        collect. For further information, please see section 6.
    </p>
    <p>
        7.15 A receipt should be obtained on collection of your Consignment. Proof of collection will be required for
        any issues that you may have with the Consignment or processing of your order. Should the driver not have a
        receipt you should print your Shippn receipt, take the drivers name and this can be signed by the driver.

    </p>
    <p>
        7.16 Please note the certain services require a bar-coded label / AWB to be printed out and attached to the
        parcel. The bar-coded label / AWB will be displayed at the end of your order. Please do this before the courier
        arrives. If you do not receive the label, please contact us as described in 13.
    </p>
    <p>
        7.17 It is the customer's responsibility to ensure that all the details are correctly completed and displayed on
        the correct Consignment as delivery will be made to the details listed on the Consignment. It is not the
        driver's responsibility to check this information, so please ensure this is checked before he leaves.
    </p>
    <h4>DELIVERY AND TRACKING</h4>
    <p>
        8.1 Delivery dates and times are not guaranteed because there might be a customs query, because the delivery is
        to a remote area as described in section 9, incorrect information having been supplied or a delivery failure
        because the receiver cannot take delivery.
    </p>
    <p>
        8.2 Transit times quoted are calculated from once collection is made and the consignment is in Transit.
    </p>
    <p>
        8.3 Deliveries are made on Working days only usually between 8.30am- 9.00 pm on any given working day.
    </p>
    <p>
        8.4 Please be advised that Shippn does not track parcels and as such it is the responsibility of the customer to
        track their own parcels, and to advise us if there are any problems. Tracking is available through our Web site
        or by contacting our Customer Service department. Tracking is available up to a period of 12 weeks from sending.
        After that stage the tracking number may be re-allocated to a new shipment.
    </p>
    <p>
        8.5 You can track all orders online and may contact our offices prior to the return to rectify any issue. Once
        returned any return charges that are due must be paid by the customer that placed the order, the order will not
        be refunded and the item will not be re shipped free of charge. The return completes the agreed contract.
        Tracking is therefore essential to potentially aid delivery and prevent any return and associated costs.
    </p>
    <p>
        8.6 If the driver has used a different tracking number than expected or if the collection point chooses to use a
        new shipping label from the driver, rather than supplied by Shippn then you will only be able to track using the
        number left at the time of collection on the Carriers own website or by contacting our Customer Service team.
    </p>
    <p>
        8.7 SHIPPN GLOBAL LOJİSTİK HİZMETLER AŞ. can only deliver to a full street address. We cannot deliver to a PO Box or BFPO address. If a
        Consignment has to be returned for these reasons, no refund will be given.
    </p>
    <p>
        8.8 Deliveries will be made to the address on the item, main front door, reception or goods in. The drivers will
        not deliver to individual department or Block numbers. Deliveries may be made to a neighboring address if the
        delivery point is out, but only if the goods can be signed for.
    </p>
    <p>
        8.9 A telephone number for the receiver is required for each Consignment (for example so that the receiver can
        be called in the event of an address query). Please note for any overseas address a local number is needed, the
        Carrier will not call an origin number. Shippn will not re ship or refund any returned item if a telephone
        number has not been provided and the Carrier has been unable to arrange delivery because a phone number has not
        been provided.
    </p>
    <p>
        8.10 Collections & Deliveries can be made any time up until 6pm.
    </p>
    <p>
        8.11 1-3 delivery attempts will be made for each Consignment, depending on the service if unsuccessful the
        Consignment will be returned to the sender. A change of address can only be requested. The contract is to
        deliver to the address on the item, so no change of address can be guaranteed.
    </p>
    <p>
        8.12 A customs invoice must be completed for ALL countries outside of the origin country. You will be guided
        online to complete this if applicable and a template will be e-mailed to you once the order is placed. An
        accurate description and reason for Export must be entered on this invoice. If customs find different items than
        declared or an incorrect reason for export stated (such as Gift when actually purchased) then a surcharge could
        be applied, items confiscated or the shipment returned (which could be chargeable). Four copies of the customs
        Invoice must be given to the driver on collection.
    </p>
    <p>
        8.13 Shippn services are generally offered “door to door”. This means that we will arrange for pick up from one
        address and a drop off at another and gain a signature on delivery. If the Consignee is out, goods may be left
        with a neighbor if they are available to sign. After the first delivery attempt the consignment may be left at a
        local access point to be collected. Further please note that a small proportion of residential addresses are
        classified as a 'Driver release area' this is where the area is deemed 'safe' and the driver is able leave the
        goods out of sight and out of weather. In exceptional circumstances, for example, but not limited to, the
        Coronavirus crisis in 2020, the couriers may take the decision to leave the goods without signature or sign for
        the goods themselves.
        <br>
        In addition we cannot offer door to door service where the delivery is going to be made in certain foreign
        countries. It is advised to check the zip code/postcode before sending for International addresses in case of
        exclusions. Please note some areas in some countries are not door to door. In some areas delivery to a Broker
        will class as delivered.
    </p>
    <p>
        8.14 Please note the Proof of delivery is only kept for up to three months after delivery.
    </p>
    <p>
        8.15 Please note that we cannot guarantee to stop any consignment once in transit, although will try to do so if
        requested.
    </p>
    <h4>REMOTE AREAS</h4>
    <p>
        9. Shipments to and from remote areas nationally and internationally on all services may be subject to delay and
        possible service downgrade. Please check the address with us prior to sending for advised transit times.
    </p>
    <h4>RESTRICTED ITEMS</h4>
    <p>
        10.1 We strongly recommend that you do not send Restricted Items using our service. This is because such items
        are fragile, dangerous or otherwise inappropriate to be carried by our services and Network. Should you proceed
        to send such goods you do so at your own risk understanding that the item will be covered for Loss only and
        excluded from any damage cover.
    </p>
    <p>
        10.2 The sign up process contains a checkbox that must be ticked to state the Prohibited / Restricted items list
        has been read and understood. You should check the Prohibited / Restricted items list to ensure that we are able
        to carry your Consignment before placing your order. If you place an order which relates to a Restricted Item,
        please note you already acknowledged the list of Prohibited / Restricted items contained and that you understand
        that Shippn seeks to limit its liability regarding such items.
    </p>
    <p>
        10.3 Given that our website accepts orders on an automated basis, we may not be aware that you have requested
        the
        carriage of a Restricted Item – even if you list an item which is restricted when you place your order. In any
        case, the Carrier will assume that the Consignment does not contain a Restricted Item and will exercise the
        level of skill and care when carrying such Consignment as is appropriate to a Consignment which does not contain
        a Restricted Item.
    </p>
    <p>
        10.4 If a Consignment which contains a Restricted Item is lost, then you will be able to make a claim up to the
        level of the applicable inclusive Insurance(as described in section 4.7 (above) in relation to that lost
        Consignment. However, if a Consignment which contains a Restricted Item is damaged, you will be unable to make a
        claim as described in Section 14 as it has been sent at your own risk (and Shippn hereby limits its liability
        accordingly).
    </p>
    <p>
        10.5 In the event of a damaged restricted item may be held for collection by the Customer. This may be the case
        if the goods are prohibited and cannot be sent through the system or damaged to such an extent that onward
        forwarding is not possible. If this is the case you will be notified in writing that goods must be collected
        within 7 days following this point they will be discarded. If the goods are so badly damaged that the contents
        are destroyed or that the goods pose a Health & Safety risk then they may be immediately discarded.
    </p>
    <p>
        10.6 Restricted items could be subject to non-collection, delay, return or confiscation by Customs. In such
        circumstances, if a restricted item is collected by the Carrier and then later returned, no refund of carriage
        will be given and return charges may be applicable.
    </p>
    <p>
        10.7 In addition you should note that if you send a restricted item, this may cause damage to other Consignments
        being carried and you may ultimately be liable for loss to those other Consignments if the senders of those
        Consignments seek to pursue you.
    </p>
    <h4>PROHIBITED ITEMS</h4>
    <p>
        11.1 PROHIBITED ITEMS MUST NOT BE SENT USING OUR SERVICES. Sending a Prohibited Item using our services is
        contrary to these terms of use. No Insurance is provided for Prohibited Items. If a Consignment which contains a
        Prohibited Item is lost or damaged, you will not be able to make a claim for any Insurance or other claim from
        Shippn relating to any loss or damage to such items (and Shippn hereby limits its liability accordingly).
    </p>
    <p>
        11.2 Given that our website accepts orders on an automated basis, we may not be aware that a Consignment
        contains a Prohibited Item – even if you list an item which is prohibited when you place your order. If we
        discover that you have sought to send a Prohibited Item we will refuse to carry it and terminate this contract
        as well as banning your account. If this is the case, and if the Carrier has collected the Consignment, you will
        be notified in writing that goods must be collected within 7 days following this point they will be discarded.
        If the goods pose a Health & Safety risk then they may be immediately discarded. No Insurance at all is offered
        for Prohibited Items and you cannot claim for any loss or delay in relation to Prohibited Items.
    </p>
    <p>
        11.3 In addition to our prohibition of the carriage of Prohibited Items, hazardous / dangerous goods are
        strictly prohibited from our services. Failure to declare hazardous / dangerous goods could lead to you being
        prosecuted where unlimited fines and imprisonment are possible. Please note that the list of
        Prohibited/Restricted items which relate to our services is not an exhaustive list of what could be considered
        to be hazardous or dangerous
    </p>
    <p>
        11.4 Item/s sent within a hazardous box will be classed as such, strictly prohibited. Please do not reuse old
        hazardous boxes.
    </p>
    <p>
        11.5 In addition you should note that if you send a Prohibited Item, this may cause damage to other Consignments
        being carried and you may ultimately be liable for loss to those other Consignments if the senders of those
        Consignments seek to pursue you.

    </p>
    <h4>CUSTOMS CLEARANCE</h4>
    <p>
        12.1 All shipments must include a customs invoice. Therefore every shipment leaving the origin country must have
        an accompanying invoice.
        <br>
        For every shipment, the invoice must include an HS code to classify the goods you are sending. It is the
        responsibility of the shipper to enter the correct HS code, otherwise the shipment could be delayed or returned
        with costs to be paid by the shipper.
        <br>
        The customs invoice must also include your VAT number. We strongly advise entering this for all shipments
        leaving the origin country to speed up the customs process.
    </p>
    <p>
        12.2 By placing an order, you are prepaying for the outward postage charges of your Consignment/s only. Shippn
        has no control over any customs queries, delays or charges that may arise. Customs charges may include VAT,
        penalties, storage charges or any other expense that has resulted from customs or governmental authorities.
        Customs charges must be paid by the receiver before delivery is made. Customs will deal directly with the
        receiver and in some cases, only the receiver. If you do not wish to pay the charges and the Consignment is
        returned, all return charges will also be passed on. Should a deadline be given before the goods are to be
        discarded and the deadline passes, no claim can be made for the loss and in addition abandonment charges may
        apply. Please note, it is the Customers responsibility to track all orders and request Shippn to intervene. If
        the tracking states the goods are held under Customs query and they are returned, or abandoned and we have not
        been contacted then no refund or claim is due.
    </p>
    <p>
        12.3 Consignments that exceed the value of $2500 may be delayed as they will require further documentation for
        clearance. In any case, you should be aware that the maximum Insurance offered by Shippn totally depends on
        carrier limits. Therefore you are advised to contact us for shipping high value goods.
    </p>
    <h4>CUSTOMER SERVICE</h4>
    <p>
        13.1 Contact Details
        <br>
        If you need to contact us for any reason, you can do so using the following methods.
        <br>
        Customer Services email: customerservices@shippn.com
        <br>
        Claims email: claims@shippn.com
        <br>
        Telephone: +1-740-848-5020

    </p>
    <p>
        13.2 Complaints
        <br>
        If you are unhappy with the resolution then please contact us by phone or by email at complaints@shippn.com
        where your enquiry will be escalated to the next level.
    </p>
    <h4>
        CLAIMS FOR LOSS OR DAMAGE
    </h4>
    <p>
        PLEASE NOTIFY US AS SOON AS POSSIBLE OF ANY CLAIM RELATING TO LOSS OR DAMAGE OF A CONSIGNMENT AND WITHIN 15 DAYS
        OF THE DAMAGE OR LOSS.
        This is because the carriers only maintain their records for a limited amount of time (normally up to a maximum
        of 12 weeks) we will then contact the relevant Carrier on your behalf to enable them to conduct an
        investigation. After the 15 day period the carrier can be approached to investigate but we can only proceed if
        the carrier's systems allow.

    </p>
    <p>
        14.1 All enquiries relating to loss or damage to Consignments should be directed through Shippn. Please do not
        contact the Carrier directly about your order. If the Carrier is contacted directly, this may cause delays in
        the resolution of any issues you may have.

    </p>
    <p>
        14.2 Please be aware that the receiver should sign for goods as "damaged" if this is the case. If goods are
        signed for as being in good condition, it will be difficult for us to show that the goods were damaged in
        transit.
    </p>
    <p>
        14.3 To process a claim, you will need to have proof that the Carrier has taken the Consignment from you. The
        best form of such proof is the receipt which the Carrier should provide you with when the Consignment is
        collected or obtaining a signature on a copy of the shipping label you have. In addition, in the event of a
        claim a copy invoice will be needed to prove the value of the Consignment.
    </p>
    <p>
        14.4 The damaged item together with all packaging should be kept until the claim is concluded as more
        photographs or inspection of the item may be necessary.
    </p>
    <p>
        14.5 If a claim is made that a Consignment has been damaged, all packaging should be kept for inspection by the
        Carrier. The item must also be available for inspection in the state it was delivered, at the address it was
        delivered to. Further journeys could cause further damage, making it difficult to assess the original damage. We
        may also ask for photographs of the internal and external packaging as well as the damaged item to process the
        claim.
    </p>
    <p>
        14.6 If it is shown that an item has been damaged in transit, we will (at our discretion) generally pay for
        damage to be repaired rather than a replacement – depending on the level of damage to the item and subject to
        the general provisions in Section 14 below. Accordingly, if you make a claim relating to a damaged item we may
        also ask for an estimate of repair costs for that item supplied by a specialist who will carry out the repair.
        If the item cannot be repaired then we would need this in writing from the specialist for a claim relating to
        the replacement of the item to be considered.
    </p>
    <p>
        14.7 In the event that a claim is approved and repair/replacement costs are paid, we will also pay the costs of
        the reasonable evaluation report and the postage costs associated with that repair/replacement. Shippn claims
        team will arrange for your item to be carried for repair through the Shippn Services when requested.
    </p>
    <p>
        14.8 A claim relating to a lost item can only be processed once the Carrier has concluded its searches for the
        item and it has been declared lost.
    </p>
    <p>
        14.9 Should the goods be recovered prior to the claim being concluded, then the claim will be cancelled and the
        goods will be returned. Should the goods be recovered after your claim has been concluded and paid, then you
        will be offered the goods back at the value that was paid back to you.
    </p>
    <p>
        14.10 Any amounts payable in relation to a lost or damaged item will only be paid to the party placing the order
        as further described in 15.3. Please ensure the exact name or company name is entered at the time of booking as
        a Surcharge will be charged to re issue a settlement. See section 6 for further details regarding surcharges.
    </p>
    <p>
        14.11 If your consignment was heavier or larger than stated for the service booked this may negate any claim for
        loss or damage.
    </p>
    <p>
        14.12 Shippn will deal with the person/company who placed the order only.
    </p>
    <h4>
        LIMITATION OF LIABILITY
    </h4>
    <p>
        15.1 Shippn's liability to you is subject to the exclusions listed in this section. This means that, with
        certain exceptions, the maximum amount of a claim that you can make against Shippn will be limited in value.
    </p>
    <p>
        15.2 SHIPPN'S MAXIMUM LIABILITY TO YOU IS GENERALLY LIMITED TO THE AMOUNT OF INSURANCE SELECTED WHEN AN ORDER
        WAS PLACED (INCLUDING ANY ENHANCED COVER) PLUS THE POSTAGE FEES PAID WITH THE ORDER.
        For example, if a standard order with $25 value has been placed with Insurance and if the cost of the postage
        was $15, then Shippn's maximum liability to you in relation to that Consignment will be limited to a total of
        $40 (being the $25 of Insurance plus the $15 cost of postage).However, Shippn's general limits on liability are
        subject to the remaining restrictions described in this section 14.
    </p>
    <p>
        15.3 Shippn's liability under these terms of use is limited to the contracting party who placed the order.
        Should the Sender have placed the order then we do not have a contract with cannot be contractually liable for
        any losses suffered by other parties than the person that placed the order.
    </p>
    <p>
        15.4 In any case, to the greatest extent that is permitted by law, Shippn hereby limits its liability to the
        direct losses suffered by you (save as expressly stated otherwise in these terms of use). Accordingly, we will
        not be liable for any claim for loss of profit, loss of use of an item, loss of revenue, administrative
        inconvenience, disappointment, or indirect or consequential loss or damage arising out of, or in relation to,
        the service you ordered.
    </p>
    <p>
        15.5 Shippn accepts no liability for loss or damage to Prohibited Items as these should not be sent using our
        services. For further information on limitations relating to Prohibited Items, see section 11.
    </p>
    <p>
        15.6 Shippn will only accept liability for damage to Restricted Items where the goods have not been carried
        using reasonable skill and care. You are reminded that we recommend that you do not send these items using our
        services and that the Carriers will assume that Consignments do not include Restricted Items. For further
        information on limitations relating to Restricted Items, see section 10.
    </p>
    <p>
        15.7 Shippn accepts no liability for loss/damage to an item if it has not been correctly packaged and/or labeled
        unless it can be shown that we have not acted with reasonable skill and care.
    </p>
    <h4>
        SEVERABILITY
    </h4>
    <p>
        If any part of this contract is found to be unenforceable as a matter of law, the enforceability of any other
        part of these terms will not be affected.
    </p>
    <h4>GOVERNING LAW</h4>
    <p>
        These terms of use and any contract between us shall be governed by and interpreted in accordance with US Law
        and the US Courts shall have jurisdiction over any disputes between us.
    </p>
    <h4>
        STATUTORY RIGHTS
    </h4>
    <p>
        These terms of use do not remove any statutory rights which you have as a consumer, which cannot be excluded.
    </p>
    <h4>RIGHTS OF THIRD PARTIES</h4>
    <p>
        A party who is not a party to this agreement (such as a receiver) cannot enforce the terms to this agreement,
        whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.
    </p>

</div>