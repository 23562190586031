import { createAction, props } from "@ngrx/store";
import { consolidationOrder } from "./consolidation.model";


export enum consolidationActionTypes {

    openConsolidation = '[Consolidation] Open Consolidation',
    saveConsolidation = '[Consolidation] Save Consolidation',
    closeConsolidation = '[Consolidation] Close Consolidation',
    addOrderToConsolidation = '[Consolidation] Add Order To Consolidation',
    removeOrderFromConsolidation = '[Consolidation] Remove Order From Consolidation',
    createParentOrderForConsolidation = '[Consolidation] Create Parent Order For Consolidation',
    updateParentOrderForConsolidation = '[Consolidation] Update Parent Order For Consolidation',
    getParentOrderForConsolidation = '[Consolidation] Get Parent Order For Consolidation',
    getConsolidationState = '[Consolidation] Get Consolidation State',

}

export const OpenConsolidation = createAction(
    consolidationActionTypes.openConsolidation,
    props<{ parentOrder: consolidationOrder }>()
)

export const CloseConsolidation = createAction(
    consolidationActionTypes.closeConsolidation
)

export const AddOrderToConsolidation = createAction(
    consolidationActionTypes.addOrderToConsolidation,
    props<{ order: consolidationOrder }>()
)

export const RemoveOrderFromConsolidation = createAction(
    consolidationActionTypes.removeOrderFromConsolidation,
    props<{ order: consolidationOrder }>()
)
export const CreateParentOrderForConsolidation = createAction(
    consolidationActionTypes.createParentOrderForConsolidation,
)
export const UpdateParentOrderForConsolidation = createAction(
    consolidationActionTypes.updateParentOrderForConsolidation,
)
export const GetParentOrderForConsolidation = createAction(
    consolidationActionTypes.getParentOrderForConsolidation,
)
export const GetConsolidationState = createAction(
    consolidationActionTypes.getConsolidationState
)