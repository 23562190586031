import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})

export class TitleAndDescriptionsService {
    constructor(

        private meta: Meta,
        private title: Title
    ) { }
    updateWebPageTitle(title) {
        this.title.setTitle(title)
    }
    updateWebsiteDescription(desc) {
        this.meta.updateTag({ name: 'description', content: desc })
    }
    updateWebsiteKeyword(keyword) {
        this.meta.updateTag({ name: 'keyword', content: keyword })
    }

}