import { Injectable } from "@angular/core";
import { Actions, act, createEffect, ofType } from "@ngrx/effects";
import { exhaustMap, map, switchMap, withLatestFrom } from "rxjs/operators";
import { SignupService } from "src/app/services/signup.service";

import * as ShoppinActions from "./shopping.actions";
import { ShoppingBFMItem, ShoppingCartProduct } from "../shopping.model";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.reducer";

@Injectable()

export class ShoppingEffects {

    token;
    cartID = localStorage.getItem('cart_id');
    anaonymousID = localStorage.getItem('anonymous_id')
    domain_from_url(url) {
        var result
        var match
        if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
            result = match[1]
            if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
                result = match[1]
            }
        }
        return result
    }

    createAnonymousID$ = createEffect(
        () => this.actions$.pipe(
            ofType(ShoppinActions.ShoppingActionTypes.OpenCart),
            switchMap(action => {
                return this.dataService.getAnonymousID('', { source: 'web' }).pipe(
                    map((data) => {
                        this.anaonymousID = data['anonymous_id'];
                        return ShoppinActions.CreateShoppingCartID({ anonymous_id: data['anonymous_id'] })
                    })
                )
            })
        )
    )
    createShoppingCartId$ = createEffect(
        () => this.actions$.pipe(
            ofType(ShoppinActions.ShoppingActionTypes.CreateCartID),
            switchMap((action: any) => {
                return this.dataService.getBFMShoppingCart('', { anonymous_id: action.anonymous_id }).pipe(
                    map((data) => {
                        this.cartID = data['shopping_cart_id']

                        let basketData = { anonymous_id: this.anaonymousID, cart_id: data['shopping_cart_id'] }

                        if (!this.token) {
                            this.dataService.createShoppingBasketOnDBWithoutToken(basketData).subscribe(data => { })

                        } else {
                            this.dataService.createShoppingBasketOnDB(basketData, this.token).subscribe(res => {
                            })
                        }

                        localStorage.setItem('cart_id', this.cartID);
                        localStorage.setItem('anonymous_id', this.anaonymousID)
                        return ShoppinActions.OpenShoppingCartSuccess({ anonymous_id: action.anonymous_id, shopping_cart_id: data['shopping_cart_id'] })
                    })
                )
            })
        )
    )

    addProductToNgrX$ = createEffect(
        () => this.actions$.pipe(
            ofType(ShoppinActions.ShoppingActionTypes.Add),
            withLatestFrom(this.store$),
            exhaustMap(([action, storeState]) => {
                let index = storeState.shoppingCart.products.findIndex(x => x.clickUrl === (action['shoppingProduct'] as ShoppingCartProduct).clickUrl && x.color === (action['shoppingProduct'] as ShoppingCartProduct).color && x.size === (action['shoppingProduct'] as ShoppingCartProduct).size);
                let itemsClone = [...storeState.shoppingCart.products];
                let products = storeState.shoppingCart.products


                let convertedProducts = storeState.shoppingCart.bfm_items ? storeState.shoppingCart.bfm_items : [];
                products.forEach(it => {
                    let newData: ShoppingBFMItem = {
                        currency_cd: 'USD',
                        img: it.imageUrl,
                        is_required: '',
                        price: it.price,
                        productDescription: `${it.color ? 'Color: ' + it.color : ''} | ${it.size ? 'Size: ' + it.size : ''}`,
                        quantity: it.quantity,
                        source: "web",
                        title: it.brandedName ? it.brandedName : it.brand,
                        url: it.clickUrl,
                        vendor: this.domain_from_url(it.clickUrl),
                        weight: "",
                        product_id: +it.id
                    }




                    if (convertedProducts.length > 0) {
                        let index = convertedProducts.findIndex(x => x.productDescription === newData.productDescription && x.url === newData.url);


                        if (index == 0) {
                            convertedProducts = [...convertedProducts]
                            convertedProducts.splice(index, 1);
                            convertedProducts = [...convertedProducts, newData]
                        } else {
                            convertedProducts = [...convertedProducts, newData]
                        }
                    } else {
                        convertedProducts = [...convertedProducts, newData]
                    }

                })
                let basketData = { anonymous_id: storeState.shoppingCart.anonymous_id, cart_id: storeState.shoppingCart.shopping_cart_id }


                if (!this.token) {
                    this.dataService.createShoppingBasketOnDBWithoutToken(basketData).subscribe(data => {


                    })
                } else {
                    this.dataService.createShoppingBasketOnDB(basketData, this.token).subscribe(data => {


                    })
                }



                return this.dataService.addBFMDataFromCartID({ items: convertedProducts }, storeState.shoppingCart.shopping_cart_id, storeState.shoppingCart.anonymous_id).pipe(
                    map(data => {

                        localStorage.setItem('anonymous_id', storeState.shoppingCart.anonymous_id);
                        localStorage.setItem('cart_id', storeState.shoppingCart.shopping_cart_id);
                        return ShoppinActions.ConvertedProducts({ bfm_items: data['items'] as ShoppingBFMItem[] })
                    })
                )
            })
        )
    )

    constructor(
        private actions$: Actions,
        private dataService: SignupService,
        private store$: Store<AppState>
    ) {
        this.store$.select('auth').subscribe(state => {
            if(state  && state?.token){
                this.token = state.token;
            }
        })
    }
}

