<div class="landing--page--main--wrapper">


    <div class="landing-page--banner--wrapper welcome-banner-wrapper">
        <div class="container welcome-banner">
            <div class="landing-page--banner--image">
                <link rel="preload" fetchpriority="high" as="image"
                    [href]="bannerImageUrL" type="image/webp">
                <img [src]="bannerImageUrL"
                    alt="Shippn International Package Forwarding Banner ">
                    <!-- <svg viewBox="-80 -80 160 130" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#0D316C" d="M31.4,-50.6C42.3,-48,54.1,-43,62.9,-34.2C71.7,-25.3,77.6,-12.7,79,0.8C80.3,14.2,77,28.3,67.8,36.5C58.5,44.7,43.4,46.9,31.2,52.7C18.9,58.5,9.4,67.9,-0.2,68.2C-9.9,68.6,-19.7,59.9,-33.5,55C-47.2,50,-64.9,48.8,-72.3,40.3C-79.7,31.8,-76.8,15.9,-77.4,-0.3C-77.9,-16.5,-81.9,-33.1,-74.8,-42C-67.6,-51,-49.4,-52.4,-35,-53.1C-20.7,-53.7,-10.4,-53.5,-0.1,-53.4C10.2,-53.2,20.4,-53.1,31.4,-50.6Z" style="transform: translate(0, -26px);" />
                      </svg>                  -->
            </div>
            <div class="welcome-banner--content">
                <h1>
                    <span>
                        Shop from <strong class="shippn-text-orange"> {{hostCountriesCount}}+ countries</strong>
                    </span>
                    <span>
                        Save up to <strong class="shippn-text-orange"> 70% on shipping</strong>
                    </span>
                </h1>
                <p>
                    Get your free address now to shop from United States, United Kingdom, Canada or {{hostCountriesCount}}+ other countries from any brand you love. We will ship your items to your doorstep anywhere in the world.
                </p>
                <button class="btn landing-page--button shippn-from-to-area-go-btn"
                    [routerLink]="['/', lang, 'signup', discountcode]">Get Your Free Address</button>
            </div>
            
        </div>
    </div>

    <!-- HOW SHIPPN WORKS -->
    <div class="shippn-homepage-container container landing-page--featured-comment">
        <h3 class="landing-page--section--title">
            HOW SHIPPN WORKS?
        </h3>
        <div class="reasons--wrapper">
            <div class="landing--page--reason reason--make--the--world--your--home ">

                <div class="hw-shippn-step-first">
                    <img src="../../../assets/img/landing-page/icon4.webp"
                    alt="Shippn International Shopping Package Forwarding Service Icon Marker on Flat surface">
                </div>
                
                <h5 class="reason--title">
                    Get an Address
                </h5>
                <p class="reason--reason--content">
                    Choose one of Shippn hosts in the country you want to shop from. No sign-up or set up fees.
                </p>
            </div>
            <div class="landing--page--reason reason--world--class--handling">
                <div class="hw-shippn-step-second">
                    <img src="../../../assets/img/landing-page/icon5.webp"
                    alt="Shippn International Shopping Package Forwarding Service Icon Mobile Shopping Phone Shop bag">
                </div>
                <h5 class="reason--title">
                    Shop From Any Store
                </h5>
                <p class="reason--reason--content">
                    Use your host’s address as your delivery address at checkout.
                </p>
            </div>
            <div class="landing--page--reason reason--optimal--shippng--rates ">
                <div class="hw-shippn-step-third">
                    <img src="../../../assets/img/landing-page/icon6.webp"
                    alt="Shippn International Shopping Package Forwarding Service Icon Hand hold package">
                </div>
                <h5 class="reason--title">
                    Receive Your Items
                </h5>
                <p class="reason--reason--content">
                    Receive your packages with trusted logistics partners, DHL, TNT, UPS, and FedEx. Save up to 70% on
                    shipping
                </p>
            </div>
        </div>
        <button class="btn landing-page--button shippn-from-to-area-go-btn"
            [routerLink]="['/', lang, 'signup', discountcode]">
            Get Your Free Address</button>
    </div>
    <!-- HOW SHIPPN WORKS -->
    <div class="custom-shape-divider-bottom-1684331186">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
    </div>
    <!-- What Makes Shippn Special -->
    <div class="whats--makes--shippn--special" style="background-color: #f8d6d3;">
        <div class="shippn-homepage-container container landing-page--shippn--special">
            <h3 class="landing-page--section--title">
                What Makes SHIPPN SPECIAL
            </h3>
            <div class="reasons--wrapper">
                <div class="landing--page--reason reason--make--the--world--your--home">
                    <img src="../../../assets/img/landing-page/icon1.webp"
                        alt="Shippn International Parcel Forwarding Service Icon House on half of the world">
                    <h5 class="reason--title">
                        Make The World <br /> Your Home
                    </h5>
                    <p class="reason--reason--content">
                        Want to buy a product from abroad, but the seller doesn’t ship to your country? No worries, you
                        can
                        use our hosts’ addresses to shop from any merchant in the world.
                    </p>
                </div>
                <div class="landing--page--reason reason--world--class--handling">
                    <img src="../../../assets/img/landing-page/icon2.webp"
                        alt="Shippn International Package Forwarding Service Icon Rocket Leaves Earth">
                    <h5 class="reason--title">
                        World-Class <br /> Handling
                    </h5>
                    <p class="reason--reason--content">
                        Let the world’s best carriers handle your packages. Our logistics partners, DHL, TNT, UPS, and
                        FedEx
                        will deliver your packages within 1-5 business days!
                    </p>
                </div>
                <div class="landing--page--reason reason--optimal--shippng--rates">
                    <img src="../../../assets/img/landing-page/icon3.webp"
                        alt="Shippn International Parcel Forwarding Service Optimal Shipping Rates Icon">
                    <h5 class="reason--title">
                        Optimal Shipping <br /> Rates
                    </h5>
                    <p class="reason--reason--content">
                        Get the best service at an affordable price. We always get the best shipping rates from our
                        logistics partners thanks to our rapidly growing volume.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- What Makes Shippn Special -->
    <div class="custom-shape-divider-top-1684331298">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
    </div>
    <!-- Partners -->
    <div class="whats--makes--shippn--special partners-bg">
        <div class="shippn-homepage-container container landing-page--shippn--special">
            <h3 class="landing-page--section--title">
                Partners
            </h3>
            <div class="partners--wrapper">
                <img src="../../../assets/img/landing-page/DHL-logo.png"
                    alt="Shippn International Shopping Package Forwarding Service Partner DHL Logo">
                <img src="../../../assets/img/landing-page/FedEx-logo.png" class="fedex"
                    alt="Shippn International Shopping Package Forwarding Service Partner Fedex Logo">
                <img src="../../../assets/img/landing-page/ups-logo.png" class="ups"
                    alt="Shippn International Shopping Package Forwarding Service Partner UPS Logo">
                <img src="../../../assets/img/landing-page/Australia-Post-logo.png" class="auspost"
                    alt="Shippn International Shopping Package Forwarding Service Partner Australia Post Logo">
                <img src="../../../assets/img/landing-page/La-Poste-logo.png"
                    alt="Shippn International Shopping Package Forwarding Service Partner La Poste Logo">
            </div>
        </div>
    </div>
    <!-- Partners -->


    <div class="custom-shape-divider-bottom-1684331462">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
    </div>

    <!-- Happy Members -->
    <div class="whats--makes--shippn--special">
        <div class="shippn-homepage-container container landing-page--shippn--special">
            <h3 class="landing-page--section--title width-percent-65">
                HERE’S WHY OVER 99,000 HAPPY MEMBERS ACROSS 160 COUNTRIES SHIP WITH SHIPPN
            </h3>
            <div class="customers--comment--wrapper">
                <div class="customers--comment" *ngFor=" let member of members  | slice:1:5; let i = index; ">
                    <p class="customers--comment--comment">
                        <span class="d-quotes"></span>{{member?.comment}}
                    </p>
                    <div class="customers--comment--customer">
                        <div class="customer--avatar">
                            <img class="member-avatar"
                            [src]="'https://d1we2hgzmci25y.cloudfront.net/assets/img/hello10-users/' + (member?.country_code) + '/' + (member?.pp_url) + '.webp'"
                                alt="Shippn International Shopping Package Parcel Forwarding Service Partner User Avatar">
                        </div>
                        <div class="customer--detail">
                            <h6 class="customer--detail--name" translate="no">
                                {{member?.name}}
                            </h6>
                            <h6 class="customer--detail--country">
                                {{member?.country}}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn landing-page--button shippn-from-to-area-go-btn"
                    [routerLink]="['/', lang, 'signup', discountcode]" style="margin-top: 20px;">Get Your Free Address</button>
        </div>
    </div>
    <!-- Happy Members -->
    <div class="custom-shape-divider-top-1684331368">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
    </div>

    


    

    

    

    <!-- Buy For Me Service -->
    <div class="landing-page--banner--wrapper bfm-section-wrapper">
        <div class="container landing-page--banner">
            <div class="landing-page--banner--content color-dark">
                <h3>
                    Easy and Seamless Shopping with Buy For Me Service
                </h3>
                <p>
                    If you have trouble ordering online we can shop on your behalf.
                    You will pay using your Shippn account and we will purchase the item for you. Use the <span class="shippn-dark-color">“{{discountcode}}”</span> code to get a <span
                        class="shippn-dark-color">10%</span> discount on your order with Buy For Me service
                </p>
                <button class="btn landing-page--button shippn-from-to-area-go-btn" [routerLink]="['/', lang, 'bfm']">
                    Try
                    Now</button>
            </div>
            <div class="landing-page--banner--image">
                <link rel="preload" fetchpriority="high" as="image" href="../../../assets/img/landing-page/customer-illustration.webp"
                    type="image/webp">
                <img src="../../../assets/img/landing-page/customer-illustration.webp"
                    alt="Shippn International Shopping Package Forwarding Service Banner The young woman wears headphone while using computer and her thumbs up">
            </div>
        </div>
    </div>
    <!-- Buy For Me Service -->

    <div class="custom-shape-divider-bottom-1684331683">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
    </div>
</div>