import {  Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { BarcodeFormat } from '@zxing/library';
import { AngularDeviceInformationService } from 'angular-device-information';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeScannerService } from "src/app/services/barcodescanner.service";
import { ToastrService } from "ngx-toastr";


@Component({
    selector: 'barcode-scanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.component.css']
})

export class BarcodeScannerComponent implements OnInit {
    @ViewChild('scanner') scanner: ZXingScannerComponent;
    @Output() onScanResult = new EventEmitter<any>();

    constructor(
        private deviceInformationService: AngularDeviceInformationService,
        private bsService: BarcodeScannerService,
        private toastr: ToastrService
    ) { }

    scannerActive: Boolean = false;
    formatsEnabled: BarcodeFormat[] = [];

    ngOnInit(): void {
        this.bsService.onbarcodeScannerStatusSource.subscribe(scannerStatus => {
            
            
            // this.scannerActive = scannerStatus
            if(scannerStatus == true){
                this.openScanner();
            } else{
                this.scannerActive = scannerStatus
            }
        })
    }

    openScanner() {
        this.scannerActive = true;
        setTimeout(() => {
            this.formatsEnabled = [
                BarcodeFormat.CODE_39,
                BarcodeFormat.CODE_128,
                BarcodeFormat.DATA_MATRIX,
                BarcodeFormat.EAN_8,
                BarcodeFormat.EAN_13,
                BarcodeFormat.CODABAR,
                BarcodeFormat.UPC_EAN_EXTENSION,
                BarcodeFormat.QR_CODE,
                BarcodeFormat.AZTEC,
                BarcodeFormat.ITF,
                BarcodeFormat.PDF_417,
                BarcodeFormat.RSS_14,
            ];
        }, 2000)
    }

    onScanError(ev) {
        this.toastr.error("Can not Read");
    }

    onScanSuccess(result) {
        this.onScanResult.next(result)
    }
    onPermissionResponse(event){
        if(event == false){
            this.bsService.changeBarcodeStatus(false)
        }
        
    }


}