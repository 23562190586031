import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countrySearch'
})
export class CountrySearchPipe implements PipeTransform {

    transform(orderList: Array<any>, search: string): any {

        if (search) {
            let input = search.toLowerCase();
            return orderList.filter((d) => d.name?.toLowerCase().indexOf(input) > -1);
        } else {
            return orderList;
        }
    }

}