import { ActionReducer, INIT, UPDATE, createReducer, on } from "@ngrx/store";
import { ShoppingCartProduct, ShoppingBFMItem } from "../shopping.model";
import * as ShoppingCartActions from "./shopping.actions";

export interface ShoppingCartState {
    products: ShoppingCartProduct[],
    status: string,
    anonymous_id: string,
    shopping_cart_id: string,
    bfm_items: ShoppingBFMItem[]
}
const shoppingCartInitialState: ShoppingCartState = {
    products: [],
    status: 'ACTIVE',
    anonymous_id: '',
    shopping_cart_id: '',
    bfm_items: []
}
export const shoppingCartReducer = createReducer(
    shoppingCartInitialState,
    on(ShoppingCartActions.ProductAdded, (state, action) => {
        let index = state.products.findIndex(x => x.clickUrl === (action.shoppingProduct as ShoppingCartProduct).clickUrl && x.color === (action.shoppingProduct as ShoppingCartProduct).color && x.size === (action.shoppingProduct as ShoppingCartProduct).size);
        if (index == -1) {
            return {
                ...state,
                products: [...state.products, (action.shoppingProduct as ShoppingCartProduct)],
                status: 'ACTIVE'
            }
        }
        else {
            const productsClone: ShoppingCartProduct[] = JSON.parse(JSON.stringify(state.products))
            productsClone[index].quantity = productsClone[index].quantity + (action.shoppingProduct as ShoppingCartProduct).quantity;

            return {
                ...state,
                products: [...productsClone],
                status: 'ACTIVE'
            }
        }
    }),
    on(ShoppingCartActions.ProductRemoved, (state, action) => {
        const productsClone: ShoppingCartProduct[] = JSON.parse(JSON.stringify(state.products))
        const found = productsClone.find(e => e.clickUrl == action.shoppingProduct.clickUrl);
        if (found) {
            productsClone.splice(productsClone.indexOf(found, 1))

            return {
                ...state,
                products: [...productsClone]
            }
        } else {
            return {
                ...state,
                products: [...productsClone]
            }
        }

    }),
    on(ShoppingCartActions.ProductsListed, (state, _action) => {
        return {
            ...state
        }
    }),
    on(ShoppingCartActions.OpenShoppingCartSuccess, (state, action) => {
        return {
            ...state,
            status: 'ACTIVE',
            anonymous_id: action.anonymous_id,
            shopping_cart_id: action.shopping_cart_id
        }
    }),
    on(ShoppingCartActions.CloseCart, (_state, action) => {
        return {
            products: [],
            status: action.status,
            anonymous_id: '',
            shopping_cart_id: '',
            bfm_items: []
        }
    }),
    on(ShoppingCartActions.ConvertedProducts, (state, action) => {
        return {
            ...state,
            bfm_items: action.bfm_items
        }
    }),
    on(ShoppingCartActions.UpdateBFMItemsFromAWS_, (state, action) => {
        return {
            ...state,
            bfm_items: action.bfm_items
        }
    })
)
export const metaReudcerLocalStorage = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {
        let nextState;
        const storageValue = localStorage.getItem('state');
        let storageState = JSON.parse(storageValue) || {};
        if (action.type === INIT || action.type === UPDATE) {
            try {
                nextState = { ...state, ...storageState };
            } catch {
                localStorage.removeItem("state");
            }
        } else {
            nextState = reducer(state, action);
            localStorage.setItem("state", JSON.stringify(nextState));
        }

        return nextState;
    };
};
