import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'terms-content',
  templateUrl: './terms-content.html',
  styleUrls: ['./terms-content.css']
})
export class TermsContent implements OnInit {

  @Input() textColorClass; 
  @Input() maxHeight;
  constructor() {
  }

  ngOnInit(): void {
  }

}
