import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'privacy-content',
  templateUrl: './privacy-content.html',
  styleUrls: ['./privacy-content.css']
})
export class PrivacyContent implements OnInit {

  @Input() textColorClass; 
  @Input() maxHeight;
  constructor() {
  }

  ngOnInit(): void {
  }

}
