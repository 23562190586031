import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'htsContentSearch'
})
export class HtsContentSearchPipe implements PipeTransform {

    transform(orderList: Array<any>, search: string): any {

        if (search) {
            let input = search.toLowerCase().trim();
            return orderList.filter((d) => d.content?.toLowerCase().indexOf(input) > -1);
        } else {
            return orderList;
        }
    }

}