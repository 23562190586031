import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { NavigationEnd, NavigationError, NavigationStart } from "@angular/router";
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }


    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message) || error?.message?.includes('Expected a JavaScript module')) {
            window.location.reload();
        }
        if (error instanceof NavigationError || error instanceof NavigationStart || error instanceof NavigationEnd ||
            (error instanceof Error && error.message.includes('Failed to load module script'))
        ) {
            window.location.reload();
        }
    }
}