<div class="modal fade" id="messageModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div style="max-width: 520px;" class="modal-dialog modal-dialog-right">
        <div style="height: 100%;" class="modal-content">
            <div style="background-color: #e5e5e5;display: flex; flex-direction: column;align-items: center;"
                class="modal-header">
                <div style="width: 100%; display: flex;align-items: center; justify-content: space-between;">
                    <div style="display: inline-flex;align-items: center;">
                        <button style="padding-left: 0px;padding-right: 0px;display: contents;" type="button"
                            class="close" data-dismiss="modal" aria-label="Close">
                            <span style="color: black;margin-right: 5px;" aria-hidden="true">&times;</span>
                        </button>
                        <h5 style="font-weight: 900;margin-right: 10px;" class="modal-title" id="exampleModalLabel">
                            Messages
                        </h5>
                    </div>

                    <div style="display: inline-flex;" *ngIf="!isShopper">
                        <div class="select--wrapper"
                            style="margin-bottom: 0px !important;background-color: white !important; border-radius:5px;">
                            <i class="fas fa-chevron-down"
                                style="position: absolute; right: 15px; top: 17px;float: right; color: #222222; font-size: 12px;"
                                aria-hidden="true"></i>
                            <select [(ngModel)]="messageAreaType"
                                style="margin-bottom: 0px !important; width: 280px !important; border-radius: 5px;padding:12px 15px !important;border: none;height: 43px;"
                                class="form-select mb-3 shippn-input form-control-sm"
                                aria-label=".form-select-lg example">
                                <option value="0">All</option>
                                <option value="1">{{messageAreaTypeText}}</option>
                                <option value="2">Messages with Shippn Support</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="order-info">
                    <h5 *ngIf="orderNumber">Order Number: <span>{{orderNumber}}</span></h5>
                    <h5 *ngIf="orderDate">Order Date: <span>{{orderDate * 1000 | date: "dd.MM.yyyy HH:mm"}}</span></h5>
                    <h5 *ngIf="orderStatus">
                        Order Status:
                        <span *ngIf="orderStatus == 1 && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Expected</span>
                        <span *ngIf="orderStatus == 2 && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Arrived</span>
                        <span *ngIf="orderStatus == 3 && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Mailbox</span>
                        <span *ngIf="(orderStatus == 4 || orderStatus == 5) && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Mailbox</span>
                        <span *ngIf="orderStatus == 6 && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Arrange Pick Up</span>
                        <span *ngIf="orderStatus == 7 && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Ready to Ship</span>
                        <span *ngIf="orderStatus == 8 && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Shipped</span>
                        <span *ngIf="orderStatus == 9 && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')">Delivered</span>
                        <span *ngIf="(orderStatus == 98 || orderStatus == 99) && (orderType != 'BFM_ORDER' && orderType != 'bfmOrder')"
                            style="color: red;">Cancelled</span>
                        <span *ngIf="orderStatus == 95 && orderType != 'BFM_ORDER'">Consolidated</span>

                        <!-- <span *ngIf="orderType == 'BFM_ORDER'">{{orderStatus}}</span> -->
                        <span *ngIf="(orderType == 'bfmOrder' || orderType == 'BFM_ORDER') && orderStatus == 0">Canceled</span>
                        <span *ngIf="(orderType == 'bfmOrder' || orderType == 'BFM_ORDER') && orderStatus == 2">Under Review</span>
                        <span *ngIf="(orderType == 'bfmOrder' || orderType == 'BFM_ORDER') && orderStatus == 4">Approved</span>
                        <span *ngIf="(orderType == 'bfmOrder' || orderType == 'BFM_ORDER') && orderStatus == 6">Purchased</span>
                        <span *ngIf="(orderType == 'bfmOrder' || orderType == 'BFM_ORDER') && orderStatus == 7">Completed</span>
                        <span *ngIf="(orderType == 'bfmOrder' || orderType == 'BFM_ORDER') && orderStatus == -1">Payment Failed</span>
                    </h5>
                </div>

            </div>
            <div #scrollMe style="overflow-y: auto;height: 585px;"
                class="modal-body">
                <div style="padding: 0;" class="container-fluid">

                    <div class="row" id="messagesRow">
                        <div *ngIf="messageData?.length == 0" class="col-md-12">
                            <p
                                style="color: black;font-size: 18px;font-weight: bold;text-align: center;margin: 0px;margin-bottom: 0px;margin-top: 10px;">
                                No unread messages.
                            </p>
                        </div>
                        <div *ngFor="let message of messageData;" class="col-md-12 message-container-message-box">
                            <div *ngIf="messageAreaType == 0">
                                <div *ngIf="message?.type == 1 && (message?.user_id != userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-bottom: 0px;margin-top: 10px;">
                                        {{message?.user_name}} {{message?.user_surname}}
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #fc7137;width: fit-content;border-radius: 10px 25px 25px 0px;">
                                        <p
                                            style="color: white;font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p>
                                    </div>

                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #fc7137;width: fit-content;border-radius: 10px 25px 25px 0px;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="shippn message jpg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: white;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="text-align: left;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>




                                </div>

                                <div *ngIf="message?.type == 1 && (message?.user_id == userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-top: 10px;text-align: right;margin-top: 10px;">
                                        You
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #3d85b0;width: fit-content;border-radius: 25px 10px 0px 25px;color: white;margin-left: auto;">
                                        <p style="font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p>
                                    </div>

                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #3d85b0;width: fit-content;border-radius: 25px 10px 0px 25px;float: right;color: white;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="Shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="Shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="Shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="Shippn message jpg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: white;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="text-align: right;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>

                                </div>

                                <div *ngIf="message?.type == 2 && (message?.user_id != userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-bottom: 0px;margin-top: 10px;">
                                        Shippn Support <span *ngIf="message?.cc === 1 && message?.cc_to === userID"
                                        style="font-weight: 400; font-size: 11px; color: #AAA;">For your information, you are copied to this message.</span>
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 10px 25px 25px 0px;">
                                        <!-- <p
                                            style="color: black;font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p> -->
                                        <div [innerHTML]="message?.text"
                                            style="color: black;font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">

                                        </div>
                                    </div>

                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 10px 25px 25px 0px;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="Shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="Shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="Shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="Shippn message jpg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: black;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="text-align: left;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>

                                </div>

                                <div *ngIf="message?.type == 2 && (message?.user_id == userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-bottom: 0px;text-align: right;margin-top: 10px;">
                                        You to Shippn Support
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 25px 10px 0px 25px;color: black;margin-left: auto;">
                                        <p style="font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p>
                                    </div>

                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 25px 10px 0px 25px;float: right;color: black;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="Shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="Shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="Shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="Shippn message jpg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: black;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="text-align: right;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="messageAreaType == 1">
                                <div *ngIf="message?.type == 1 && (message?.user_id != userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-bottom: 0px;margin-top: 10px;">
                                        {{message?.user_name}} {{message?.user_surname}}
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #fc7137;width: fit-content;border-radius: 10px 25px 25px 0px;">
                                        <p
                                            style="color: white;font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p>
                                    </div>
                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #fc7137;width: fit-content;border-radius: 10px 25px 25px 0px;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="Shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="Shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="Shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="Shippn message jpg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: white;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="text-align: left;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>
                                </div>
                                <div *ngIf="message?.type == 1 && (message?.user_id == userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-top: 10px;text-align: right;margin-top: 10px;">
                                        You
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #3d85b0;width: fit-content;border-radius: 25px 10px 0px 25px;color: white;margin-left: auto;">
                                        <p style="font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p>
                                    </div>
                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #3d85b0;width: fit-content;border-radius: 25px 10px 0px 25px;float: right;color: white;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="Shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="Shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="Shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="Shippn message jpg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: white;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="text-align: right;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="messageAreaType == 2">
                                <div *ngIf="message?.type == 2 && (message?.user_id != userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-bottom: 0px;margin-top: 10px;">
                                        Shippn Support <span *ngIf="message?.cc === 1 && message?.cc_to === userID"
                                        style="font-weight: 400; font-size: 11px; color: #AAA;">For your information, you are copied to this message.</span>
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 10px 25px 25px 0px;">
                                        <p
                                            style="color: black;font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p>
                                    </div>
                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 10px 25px 25px 0px;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="Shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="Shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="Shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="Shippn message jpg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: black;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="text-align: left;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>

                                </div>
                                <div *ngIf="message?.type == 2 && (message?.user_id == userID)">
                                    <p
                                        style="color: black;font-size: 15px;font-weight: bold;margin: 0px;margin-bottom: 0px;text-align: right;margin-top: 10px;">
                                        You to Shippn Support
                                    </p>
                                    <div *ngIf="message?.text"
                                        style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 25px 10px 0px 25px;color: black;margin-left: auto;">
                                        <p style="font-size: 15px;font-weight: 300;margin: 0px;white-space: pre-line;">
                                            {{message?.text}}
                                        </p>
                                    </div>
                                    <div *ngIf="message?.attachments.length > 0" style="display: table;width: 100%;">
                                        <div *ngFor="let file of message?.attachments;"
                                            style="padding: 10px 15px;background-color: #e5e5e5;width: fit-content;border-radius: 25px 10px 0px 25px;float: right;color: black;display: inline-flex;margin-top: 5px;">
                                            <div style="display: inline-flex;align-items: center;">
                                                <img *ngIf="file?.type == 'png'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                                    alt="Shippn message png icon">
                                                <img *ngIf="file?.type == 'pdf'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                                    alt="Shippn message pdf icon">
                                                <img *ngIf="file?.type == 'jpeg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <img *ngIf="file?.type == 'bmp'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                                    alt="Shippn message bmp icon">
                                                <img *ngIf="file?.type == 'jpg'" style="width: 25px;margin-right: 5px;"
                                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                                    alt="Shippn message jpeg icon">
                                                <a href="{{file?.path}}" target="_blank"
                                                    style="color: black;font-size: 15px;font-weight: bold;margin: 0px;cursor: pointer;">
                                                    {{file?.filename}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="text-align: right;margin-top: 5px;">
                                        <p
                                            style="color: #acacac;font-size: 14px;font-weight: normal;margin: 0px;margin-bottom: 0px;">
                                            {{message?.date * 1000 | date:"dd.MM.yyyy HH:mm"}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: inline-block;flex-wrap: nowrap;" class="modal-footer">
                <!-- <div>
                    <ngx-dropzone (change)="onSelect($event)">
                    <ngx-dropzone-label>Drop it, baby!</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
                </div> -->
                <div style="background-color: #e6e6e6;border-radius: 5px;margin-bottom: 10px;">
                    <div class="row" *ngIf="!isShopper">
                        <div (click)="changeMessageType(1)" style="text-align: center;cursor: pointer;"
                            class="col-md-6">
                            <div [ngStyle]="{'border': messageType == 1 ? '3px solid #fc7137' : '3px solid transparent' }"
                                style="padding: 10px 0px 10px 10px;min-height: 100%;display: inline-flex;align-items: center;text-align: center;width: 100%;border-radius: 5px;">
                                <p
                                    style="color: black;font-size: 14px;font-weight: bold;margin: 0px;margin-bottom: 0px;margin-top: 0px;width: 100%;">
                                    {{messageTypeText}}</p>
                            </div>

                        </div>
                        <div (click)="changeMessageType(2)" style="text-align: center;cursor: pointer;"
                            class="col-md-6">
                            <div [ngStyle]="{'border': messageType == 2 ? '3px solid #fc7137' : '3px solid transparent' }"
                                style="padding: 10px 0px 10px 0px;min-height: 100%;display: inline-flex;align-items: center;text-align: center;width: 100%;border-radius: 5px;">
                                <p
                                    style="color: black;font-size: 14px;font-weight: bold;margin: 0px;margin-bottom: 0px;margin-top: 0px;width: 100%;">
                                    Send message to Shippn Support</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isShopper || orderType == 'bfmOrder'">
                        <div class="col-md-12 send-message-copy-to-shippn-support" [formGroup]="checkboxControl"
                            style="align-items: flex-start; flex-direction: column; gap:10px">
                            <div class="sendCopyCheckbox-wrapper" *ngIf="isShopper">
                                <input class="form-check-input" formControlName="sendCopyCheckbox" type="checkbox"
                                    id="sendCopyCheckbox" (change)="sendCopyCheckboxChange()">
                                <label style="font-size: 14px;font-weight: 500;" class="form-check-label"
                                    for="sendCopyCheckbox" id="sendCopyCheckboxLabel">
                                    Send copy to Shippn Support
                                </label>

                            </div>
                            <p style="font-size: 12px;font-weight: 500; color:#ff6c36; margin:0">
                                *Shippn Support will get back to you within 12 hours.
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="showDragDropArea">
                    <ngx-dropzone [multiple]="false" style="height: auto;" (change)="onSelect($event)">
                        <ngx-dropzone-label>
                            <img style="width: 55px;" src="../../../../../assets/img/uploaded-file-icon.png"
                                alt="Shippn upload file icon">
                            <p
                                style="color: black;font-size: 14px;font-weight: bold;margin: 0px;margin-bottom: 3px;margin-top: 10px;">
                                Drag and drop or browse to choose a file</p>

                            <p style="color: black;font-size: 11px;font-weight: 300;margin: 0px;margin-top: 10px;">We
                                support only <strong>jpg, jpeg, png, bmp and pdf</strong> files up to <br> 10 MB with a
                                maximum of 5 files in total</p>
                        </ngx-dropzone-label>
                        <!-- <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview> -->
                    </ngx-dropzone>

                    <div *ngFor="let f of files"
                        style="box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);padding: 10px;border-radius: 5px;margin-top: 10px;">
                        <div style="display: inline-flex;">
                            <div style="margin-right: 5px;">
                                <img *ngIf="f.type == 'image/png'" style="width: 26px;"
                                    src="../../../../../assets/img/shippn-message-png-icon.png"
                                    alt="Shippn message png icon">
                                <img *ngIf="f.type == 'image/jpeg'" style="width: 26px;"
                                    src="../../../../../assets/img/shippn-message-jpeg-icon.png"
                                    alt="Shippn message jpeg icon">
                                <img *ngIf="f.type == 'image/bmp'" style="width: 26px;"
                                    src="../../../../../assets/img/shippn-message-bmp-icon.png"
                                    alt="Shippn message bmp icon">
                                <img *ngIf="f.type == 'image/jpg'" style="width: 26px;"
                                    src="../../../../../assets/img/shippn-message-jpg-icon.png"
                                    alt="Shippn message jpg icon">
                                <img *ngIf="f.type == 'application/pdf'" style="width: 26px;"
                                    src="../../../../../assets/img/shippn-message-pdf-icon.png"
                                    alt="Shippn message pdf icon">
                            </div>

                            <div>
                                <p style="font-size: 10px;font-weight: bold;margin-bottom: 0;" translate="no">{{ f.name
                                    }}</p>
                                <p style="font-size: 10px;font-weight: 300;margin-bottom: 0;">{{f.size / 1024 |
                                    number:'1.2-2'}} KB</p>
                            </div>
                        </div>

                        <div (click)="onRemove(f)" style="float: right;cursor: pointer;">
                            <i style="color: #b2b2b2;font-size: 18px;margin-top: 5px;" class="fas fa-times-circle"></i>
                        </div>

                    </div>
                </div>
                <div style="display: inline-flex;width: 100%;">
                    <div style="margin-right: 15px;" class="input-group">
                        <textarea [(ngModel)]="textArea" [placeholder]="textAreaPlaceholder" [disabled]="!messageType"
                            style="resize: none;border-right: none;height: 137px;font-size: 14px;" class="form-control"
                            aria-label="With textarea"></textarea>
                        <span (click)="changeDragDropAreaStatus()"
                            style="align-items: center;background: none;border-left: none;cursor: pointer;"
                            class="input-group-text"><img style="width: 20px;"
                                src="../../../../../assets/img/shippn-attachment-icon.png"
                                alt="Shippn message attachment icon"></span>

                    </div>
                    <div style="display: flex;align-items: center;">
                        <button (click)="sendMessage()"
                            style="background: #3d85b0;border: #3d85b0;height: 137px;font-size: 14px;width: 75px;"
                            type="button" class="btn btn-secondary" data-bs-dismiss="modal">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>