import { createReducer, on } from "@ngrx/store";
import * as ConsolidationActions from "./consolidation.actions";
import { consolidationOrder } from "./consolidation.model";
export interface ConsolidationState {
    status: number | null;
    parentOrder: consolidationOrder | null;
    childOrders: consolidationOrder[] | null;
}
const consolidationInitialState: ConsolidationState = {
    status: null,
    parentOrder: null,
    childOrders: []
}
export const consolidationReducer = createReducer(
    consolidationInitialState,
    on(ConsolidationActions.OpenConsolidation, (state, action) => {
        return {
            ...state,
            status: 1,
            parentOrder: action.parentOrder,
        }
    }),
    on(ConsolidationActions.CloseConsolidation, (state, action) => {
        return {
            ...state,
            status: 0,
            parentOrder: null,
            childOrders: []
        }
    }),
    on(ConsolidationActions.AddOrderToConsolidation, (state, action) => {
        const childOrdersClone = state.childOrders;
        const found = childOrdersClone.find(e => e.order_id == action.order.order_id);
        if (found) {
            return state;

        } else {
            return {
                ...state,
                childOrders: [...state.childOrders, action.order]
            }
        }
    }),
    on(ConsolidationActions.RemoveOrderFromConsolidation, (state, action) => {
        const childOrdersClone: consolidationOrder[] = JSON.parse(JSON.stringify(state.childOrders));
        const found = childOrdersClone.find(e => e.order_number == action.order.order_number);
        if (found) {
            if (childOrdersClone.length > 1) {
                const index = childOrdersClone.indexOf(found);
                childOrdersClone.splice(index, 1);
                return {
                    ...state,
                    childOrders: childOrdersClone ? [...childOrdersClone] : []
                }
            } else {
                return {
                    ...state,
                    parentOrder: null,
                    childOrders: []
                }
            }

        } else {
            return state;
        }

    }),
    on(ConsolidationActions.UpdateParentOrderForConsolidation, (state, action) => {
        const childOrdersClone: consolidationOrder[] = JSON.parse(JSON.stringify(state.childOrders));
        if (childOrdersClone.length >= 1) {
            return {
                ...state,
                parentOrder: childOrdersClone[0],
                childOrders: childOrdersClone.splice(1, childOrdersClone.length)
            }
        } else {
            return {
                ...state,
                parentOrder: null,
                childOrders: []
            }
        }
    })
)